import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import dynamic from "next/dynamic";
import HeaderAccountDropdown from "../dropdown/account-dropdown";
import HeaderSearch from "../header-search";
import style from "./main-toolbar.module.scss";
import dheaderStyle from "../../styles/d-header.module.scss";
import Cart from "../cart-preview";
import FnpLogo from "../fnp-logo";

const LocationLock = dynamic(() => import("../location-lock/index"));

/**
 * Component of Main toolbar has the search , Location lock , account dropdown , Logo.
 *
 * @param {object} root0  props passed to the component .
 * @param {boolean} root0.showSearch conditionally enable the search bar in the main tool bar section
 * @param {boolean} root0.showCart conditionally enable the Cart info in the main tool bar section
 * @param {boolean} root0.showAccount conditionally enable the Account info in the main tool bar section
 * @param {boolean} root0.showLocationLock conditionally enable the location lock in the main tool bar section
 * @returns {React.ReactElement} returns the Main toolbar component.
 */
function MainToolbar({ showSearch, showCart, showAccount, showLocationLock }) {
  const { catalogId, configData } = useSelector((state) => state.appConfigs);
  const userDetails = useSelector((state) => state.userDetails);
  const isLoggedIn = userDetails?.checkLoginInfo?.loggedin;
  const { accountDropDown, loggedInAccountDropDown } = configData?.desktop;
  const [updatedAccountDropdown, setUpdatedAccountDropdown] = useState(accountDropDown);

  useEffect(() => {
    if (isLoggedIn && updatedAccountDropdown.length > 0) {
      setUpdatedAccountDropdown((prevState) => {
        const arr = prevState.slice(1);
        return arr;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <div className={`${style["main-toolbar"]} ${dheaderStyle["align-items"]}`}>
      <div className={`${dheaderStyle["align-items"]}`}>
        <FnpLogo catalogId={catalogId} />
        {showSearch ? <HeaderSearch /> : null}
        {showLocationLock && <LocationLock />}
      </div>
      <div className={`${style["cart-account-drop-down"]}`}>
        {showCart && <Cart />}
        {showAccount && (
          <HeaderAccountDropdown
            itemsArray={updatedAccountDropdown}
            userDetails={userDetails}
            loggedinItems={loggedInAccountDropDown}
          />
        )}
      </div>
    </div>
  );
}
MainToolbar.propTypes = {
  showCart: PropTypes.bool,
  showAccount: PropTypes.bool,
  showSearch: PropTypes.bool,
  showLocationLock: PropTypes.bool,
};

MainToolbar.defaultProps = {
  showCart: false,
  showAccount: false,
  showSearch: false,
  showLocationLock: true,
};
export default MainToolbar;
