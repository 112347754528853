import React from "react";
import { Box, Link, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

import styles from "./styles/fnp-logo.module.scss";

const useStyles = makeStyles(() => ({
  homePagelogo: {
    position: "relative",
    top: "8px",
    left: "-5px",
  },
  logoWrapper: {
    width: "190px",
  },
}));

/**
 * This component is used to show fnp logo in the m-site header
 *
 * @param {object} root0 props from parent
 * @param {string} root0.catalogId current catalog id
 *
 * @returns {React.ReactElement} - renders fnp-logo component
 */
const FNPLogo = ({ catalogId }) => {
  const classes = useStyles();
  let homeURL;
  if (catalogId === "india") {
    homeURL = `/`;
  } else {
    homeURL = catalogId === "singapore" ? `/${catalogId}/rakhi` : `/${catalogId}/gifts`;
  }
  return (
    <>
      <div className={classes.logoWrapper}>
        <Link href={homeURL} className={classes.homePagelogo}>
          <Box component="span" className={styles.logoSvg} />
        </Link>
      </div>
    </>
  );
};

FNPLogo.propTypes = {
  catalogId: PropTypes.string,
};

FNPLogo.defaultProps = {
  catalogId: "india",
};

export default FNPLogo;
