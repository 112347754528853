import { endpointConst } from "./dataLayerConstants";

const burstCacheConfig = {
  [endpointConst.deleteCartItem]: [endpointConst.viewCart],
  [endpointConst.updateAddOn]: [endpointConst.viewCart],
  [endpointConst.addItem]: [endpointConst.viewCart],
  [endpointConst.addAddon]: [endpointConst.viewCart],
  [endpointConst.getCompleteCartItem]: [endpointConst.viewCart],
  [endpointConst.login]: [endpointConst.viewCart],
  [endpointConst.signUp]: [endpointConst.viewCart],
  [endpointConst.otpLogin]: [endpointConst.viewCart],
  [endpointConst.newPasswordLogin]: [endpointConst.viewCart],
  [endpointConst.setCartItemValue]: [endpointConst.viewCart],
  [endpointConst.logout]: [endpointConst.viewCart, endpointConst.gwb, endpointConst.recentSearchItemList],
  [endpointConst.socialLogin]: [endpointConst.viewCart],
  [endpointConst.thankYouPage]: [endpointConst.gwb],
};

const cmsConfig = {
  [endpointConst.configurecallandchattime]: true,
  default: false,
};

const cacheConfig = {
  [endpointConst.viewCart]: {
    ttl: 900000,
  },
  [endpointConst.gwb]: {
    ttl: 900000,
  },
  [endpointConst.configurecallandchattime]: {
    ttl: 900000,
  },
  default: {
    ttl: 900000,
  },
};

export { burstCacheConfig, cacheConfig, cmsConfig };
