import { call, put, takeEvery, select } from "redux-saga/effects";
import CommonPageActionsConstants from "../../src/action-constants/common-page-actions-constants";
import {
  setErrorData,
  setPageMenuData,
  getRRConfigError,
  setWidgetHelperData,
  getCallChatTime,
} from "../../src/actions/common-page-actions";
import {
  fetchContentFromCMS,
  fetchRRConfigContent,
  fetchWidgetHelpersFromCMS,
} from "../../src/services/api/common/common-api";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
/**
 * This is a worker saga function that calls the menu api and sets the data in reducer
 *
 * @param {object} action - contains the response payload information of menu content
 */
function* handlePageMenuData(action) {
  try {
    const res = yield call(fetchContentFromCMS, action.payload);
    if (res) {
      yield put(setPageMenuData(res));
    }
  } catch (error) {
    yield put(setErrorData(error.message));
  }
}

/**
 * This is a worker saga function that calls the menu api and sets the data in reducer
 *
 * @param {object} action - contains the response payload information of menu content
 */
function* handleWidgetHelperData(action) {
  try {
    const widgetHelperScr = yield call(fetchWidgetHelpersFromCMS, action.payload);
    if (widgetHelperScr) {
      yield put(setWidgetHelperData(widgetHelperScr));
    }
  } catch (error) {
    yield put(setErrorData(error.message));
  }
}

/**
 *  Function to get RR config from CMS
 *
 * @param {object} action contains the response payload information of CMS content
 */
function* getRRConfigForDateLock(action) {
  try {
    const response = yield call(fetchRRConfigContent, action.payload);
    const isDateLockEnabled = yield select((store) => store.appData.isDateLockEnabled);
    if (response?.active !== isDateLockEnabled) {
      yield put({ type: APP_CONSTANTS.SET_IS_DATE_LOCK_ENABLED, payload: response?.active });
    }
  } catch (error) {
    yield put(getRRConfigError(error.message));
  }
}
/**
 * this function fetches data from CMS call center related configration
 *
 * @param {object} action contains the response payload information of CMS content
 */
function* fetchCallChatTime(action) {
  try {
    const res = yield call(getCallChatTime, action.payload);
    yield put({ type: CommonPageActionsConstants.GET_CALL_CHAT_TIME_SUCCESS, payload: { ...res, ...action.payload } });
  } catch (error) {
    yield put({ type: CommonPageActionsConstants.GET_CALL_CHAT_TIME_FAIL });
  }
}
/**
 * Watcher saga for common page
 */
export default function* watchCommonPageDataSaga() {
  yield takeEvery(CommonPageActionsConstants.SERVER_REQUEST_PAGE_MENU_DATA, handlePageMenuData);
  yield takeEvery(CommonPageActionsConstants.SERVER_REQUEST_WIDGET_HELPER_DATA, handleWidgetHelperData);
  yield takeEvery(CommonPageActionsConstants.GET_CALL_CHAT_TIME, fetchCallChatTime);
  yield takeEvery(APP_CONSTANTS.INITIATE_RR_CONFIG, getRRConfigForDateLock);
}
