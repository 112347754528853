const CommonPageActionsConstants = {
  SERVER_SET_PAGE_META_DATA: "SERVER_SET_PAGE_META_DATA",
  SERVER_SET_PAGE_CANONICAL_DATA: "SERVER_SET_PAGE_CANONICAL_DATA",
  SERVER_SET_PAGE_OG_TAG_DATA: "SERVER_SET_PAGE_OG_TAG_DATA",
  SERVER_SET_PAGE_TWITTER_TAG_DATA: "SERVER_SET_PAGE_TWITTER_TAG_DATA",
  SERVER_SET_PAGE_SCHEMA_TAG_DATA: "SERVER_SET_PAGE_SCHEMA_TAG_DATA",
  SERVER_SET_PAGE_REL_ALT_DATA: "SERVER_SET_PAGE_REL_ALT_DATA",
  SERVER_SET_PAGE_MENU_DATA: "SERVER_SET_PAGE_MENU_DATA",
  SERVER_SET_PAGE_BREADCRUMBS_DATA: "SERVER_SET_PAGE_BREADCRUMBS_DATA",
  SERVER_SET_PAGE_FOOTER_DATA: "SERVER_SET_PAGE_FOOTER_DATA",
  SERVER_SET_PAGE_HEAD_ANALYTICS_DATA: "SERVER_SET_PAGE_HEAD_ANALYTICS_DATA",
  SERVER_SET_PAGE_BODY_ANALYTICS_DATA: "SERVER_SET_PAGE_BODY_ANALYTICS_DATA",
  SERVER_SET_PAGE_BOTTOM_ANALYTICS_DATA: "SERVER_SET_PAGE_BOTTOM_ANALYTICS_DATA",
  SERVER_REQUEST_PAGE_MENU_DATA: "SERVER_REQUEST_PAGE_MENU_DATA",
  SERVER_SET_ERROR_DATA: "SERVER_SET_ERROR_DATA",
  SERVER_REQUEST_WIDGET_HELPER_DATA: "SERVER_REQUEST_WIDGET_HELPER_DATA",
  SERVER_SET_WIDGET_HELPER_DATA: "SERVER_SET_WIDGET_HELPER_DATA",
  SERVER_SET_PRELOAD_DATA: "SERVER_SET_PRELOAD_DATA",
  SERVER_SET_LEFT_MENU_DYNAMIC_DATA: "SERVER_SET_LEFT_MENU_DYNAMIC_DATA",
  SHOW_MEDIA_INFO: "SHOW_MEDIA_INFO",
  GET_CALL_CHAT_TIME: "GET_CALL_CHAT_TIME",
  GET_CALL_CHAT_TIME_SUCCESS: "GET_CALL_CHAT_TIME_SUCCESS",
  GET_CALL_CHAT_TIME_FAIL: "GET_CALL_CHAT_TIME_FAIL",
};

export default CommonPageActionsConstants;
