import React, { useState } from "react";
import Image from "next/image";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import UpdateQuantity from "./updateQuantity";
import DeleteConfirm from "./deleteConfirm";
import MainProductPanelInfo from "./mainProductPanelInfo";
import CurrencyDetails from "./currencyDetails";

const DELETE_MESSAGE = "Are you sure you want to delete this item?";

const useStyles = makeStyles((theme) => ({
  panelBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  baseItemNumber: {
    position: "absolute",
    borderRadius: "0 4px 4px 0",
    background: theme.color?.olive,
    width: 20,
    height: 24,
    paddingTop: 2,
    textAlign: "center",
    color: "#fff",
    fontSize: 16,
    fontWeight: 500,
    left: 2,
    top: 5,
    zIndex: 1,
  },
  productImage: {
    width: 75,
    height: 75,
    marginRight: 12,
  },
  figure: {
    display: "flex",
    maxWidth: "80%",
    flexBasis: "80%",
  },
  figCaptionWrapper: {
    width: "calc(100% - 75px)",
    paddingLeft: "12px",
  },
  dot: {
    display: "inline-block",
    width: 4,
    height: 4,
    background: "#ccc",
    borderRadius: "50%",
    margin: "0 10px 3px 5px",
  },
  gray: {
    color: theme.color?.lightGrey,
  },
  addonsLabel: { padding: "5px 2px", display: "block" },
  addonsSection: { background: "#f8f8f8", padding: 10 },
  addonItem: {
    display: "flex",
    paddingBottom: 10,
    borderBottom: "1px solid #d8d8d8",
    position: "relative",
    marginBottom: 10,
    justifyContent: "space-between",
    "&:last-child": {
      borderBottom: "none",
      padding: 0,
      margin: 0,
    },
  },
  addonProductName: {
    fontSize: 14,
    overflow: "hidden",
    lineHeight: 1.4,
    fontWeight: 400,
    color: theme.color?.darkBlack,
    marginBottom: 5,
    margin: 0,
    width: "90%",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },

  fnpRed: {
    color: theme.color?.red,
  },
  price: {
    fontSize: 14,
  },
  addOnPriceTag: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    justifyContent: "space-between",
    paddingRight: "30px",
  },
  addonPriceContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  addonQuantity: { color: theme.color?.darkBlack, paddingLeft: 3, height: 24 },
  rupee: {
    verticalAlign: "middle",
    fontFamily: "Roboto,sans-serif",
    fontSize: 16,
    "&::before": {
      content: '"\u20B9"',
    },
  },
  deleteBtn: {
    borderRadius: 4,
    padding: 5,
    minWidth: 60,
    border: "1px solid #999",
    color: theme.color?.lightGrey,
    backgroundColor: "#fff",
    fontSize: 12,
    fontWeight: 500,
    textAlign: "center",
    marginTop: 5,
  },
  pzInfo: {
    verticalAlign: "middle",
    marginLeft: 2,
    fontSize: 14,
    color: theme.color?.darkBlack,
  },
  msgCardSaveIcon: {
    verticalAlign: "middle",
    fontSize: 18,
    color: theme.color?.green,
  },
  deleteProduct: {
    cursor: "pointer",
    height: "fit-content",
  },
  addQtyContainer: {
    alignItems: "center",
    wordWrap: "normal",
    flexShrink: 0,
  },
  deleteIconStyle: { fontSize: 30, marginRight: 4, color: "#555555" },
  deleteAddonIconStyle: { fontSize: 30, marginRight: 0, color: "#555555" },
}));

/**
 * Component to render cart items.
 *
 * @param {*} param0 all the props passed to the component.
 * @param {string} param0.giftItem It used to get name of product image.
 * @param {boolean} param0.isCart To identify whether component is used for cart screen.
 * @param {boolean} param0.isMultiImageUploadOn To identify whether multi image upload is on or not.
 *
 * @returns {React.ReactElement} jsx for the order item component.
 */
const OrderItem = ({
  giftItem,
  currency,
  isCart,
  isMultiImageUploadOn,
  updateQuantityAction,
  deleteOrderItemAction,
  currencies,
  isMobile,
  ...props
}) => {
  const classes = useStyles(props);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [isMainItemDeleted, setIsMainItemDeleted] = useState(false);
  const [itemToDelete, setItemToDelete] = useState({});
  const { isCdpEnabled } = useSelector((state) =>
    isMobile ? state.appConfigs.configData.mobile : state.appConfigs.configData.desktop,
  );
  const cartId = useSelector((state) => state.userDetails.cartId);

  const addons = giftItem.addons || [];

  /**
   *  function that closes confirmation modal
   *
   * @param {string} action contains value of the response by user.
   */
  const handleCloseDeleteConfirm = (action) => {
    if (action === "Y") {
      deleteOrderItemAction(itemToDelete);
      if (isCdpEnabled && window.cdp) {
        window.cdp.removePro(cartId, itemToDelete.productId);
      }
    }
    setIsMainItemDeleted(false);
    setOpenDeleteConfirm(false);
  };

  /**
   * Function sets item to delete in state and opens delete confirm
   *
   * @param {*} item item you want to delete.
   */
  const handleDeletePress = (item) => {
    setItemToDelete(item);
    if (!item.isAddon) {
      setIsMainItemDeleted(true);
    }
    setOpenDeleteConfirm(true);
  };

  return (
    <div
      className="product-wrapper"
      data-product-wrapper-index={giftItem.giftIndex}
      data-item-index={giftItem.cartItemIndex}
    >
      <span className={classes.baseItemNumber}>{giftItem.giftIndex}</span>
      <div
        className={classes.panelBox}
        data-product-id={giftItem.mainProduct.productId}
        data-product-price={giftItem.mainProduct.price}
      >
        <MainProductPanelInfo giftItem={giftItem} currency={currency} currencies={currencies} isMobile={isMobile} />
        <Grid
          className={`icon ${classes.deleteProduct}`}
          id="deleteProduct"
          value={giftItem.cartItemIndex}
          data-product-id={giftItem.mainProduct.productId}
          data-delete-param={`DELETE_${giftItem.cartItemIndex}=${giftItem.cartItemIndex}&productId=${giftItem.mainProduct.productId}&format=json`}
          onClick={() => {
            handleDeletePress({
              productId: giftItem.mainProduct.productId,
              index: giftItem.cartItemIndex,
              isAddon: false,
            });
          }}
        >
          <DeleteIcon className={classes.deleteIconStyle} />
        </Grid>
        <input
          type="hidden"
          name={`personalizedImage_${giftItem.cartItemIndex}`}
          value={
            typeof giftItem.mainProduct.personalizedImage === "object"
              ? JSON.stringify(giftItem.mainProduct.personalizedImage)
              : giftItem.mainProduct.personalizedImage || ""
          }
        />
        <input
          type="hidden"
          name={`personalizedText_${giftItem.cartItemIndex}`}
          value={giftItem.mainProduct.personalizedText || ""}
        />
      </div>
      {giftItem.isPersonalizationSaved ? (
        <div className={classes.pzBlock}>
          <CheckCircleIcon className={classes.msgCardSaveIcon} />
          <span className={classes.pzInfo}>Customization Saved</span>
        </div>
      ) : null}
      {addons.length > 0 && (
        <div id="addons">
          <span className={classes.addonsLabel}>Addons</span>
          <div className={classes.addonsSection}>
            {addons.map((item) => (
              <div
                className={classes.addonItem}
                key={item.productId}
                data-addon-index={item.itemIndex}
                data-product-id={item.productId}
                data-product-price={item.unitPrice}
              >
                <figure className={classes.figure}>
                  <span style={{ width: 75, height: 75 }}>
                    <Image
                      src={item.productImage}
                      alt={item.productName}
                      className={classes.productImage}
                      width={75}
                      height={75}
                      layout="fixed"
                      unoptimized
                    />
                  </span>
                  <figcaption className={classes.figCaptionWrapper}>
                    <h3 className={classes.addonProductName}>{item.productName}</h3>
                    <div className={` ${classes.addOnPriceTag} ${!isMobile && classes.addonPriceContainer}`}>
                      <div className={classes.addQtyContainer}>
                        <CurrencyDetails
                          currencies={currencies}
                          selectedCurrency={currency}
                          price={item.unitPrice}
                          priceStyle={{ color: "#222222", wordWrap: "normal" }}
                        />
                        <span className={classes.addonQuantity}>{` x ${item.quantity}`}</span>
                      </div>
                      <UpdateQuantity
                        isMobile={isMobile}
                        quantity={item.quantity}
                        itemIndex={item.itemIndex}
                        productId={item.productId}
                        handleUpdate={updateQuantityAction}
                      />
                    </div>
                  </figcaption>
                </figure>
                <Grid
                  className={`icon ${classes.deleteProduct}`}
                  id="deleteProduct"
                  value={item.itemIndex}
                  data-product-id={item.productId}
                  data-delete-param={`DELETE_${item.itemIndex}=${item.itemIndex}&productId=${item.productId}&format=json`}
                  onClick={() => {
                    handleDeletePress({ productId: item.productId, index: item.itemIndex, isAddon: true });
                  }}
                >
                  <DeleteIcon className={classes.deleteAddonIconStyle} />
                </Grid>
              </div>
            ))}
          </div>
        </div>
      )}
      <DeleteConfirm
        textContent={DELETE_MESSAGE}
        open={openDeleteConfirm}
        productId={itemToDelete.productId}
        isMainItemDeleted={isMainItemDeleted}
        handleClose={handleCloseDeleteConfirm}
      />
    </div>
  );
};

const propTypes = {
  giftItem: PropTypes.objectOf(PropTypes.any).isRequired,
  currency: PropTypes.string.isRequired,
  isCart: PropTypes.bool.isRequired,
  isMultiImageUploadOn: PropTypes.bool.isRequired,
  updateQuantityAction: PropTypes.func.isRequired,
  deleteOrderItemAction: PropTypes.func.isRequired,
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

OrderItem.propTypes = propTypes;

export default OrderItem;
