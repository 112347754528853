import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Slider from "react-slick";
import { dateToDMY, sanitizeDateStringToDate } from "../../src/utils/common";
import CHECKOUT_CONSTANTS from "../../src/action-constants/checkout-constants";
import { removeItemBasedOnKey } from "../../src/utils/search-utils";
import ModalDateTimePicker from "../desktop/pdp/modal-date-time-picker";
import ShippingMethod from "../desktop/pdp/shipping-method";
import DeliveryCalender from "../desktop/pdp/calender";
import slickStyles from "../desktop/pdp/style/calendar-slider-slick.module.scss";
import { checkIsInternationalProduct } from "../../src/utils/checkout";
import TimeSlot from "../desktop/pdp/time-slot";

/**
 * This method is used to render International Shipping selection and Delivery Calender
 *
 * @param {object} root0 it includes object from parent component
 * @param {Function} root0.onSelect to handle selection.
 * @param {object} root0.giftItem Gift item
 * @param {Function} root0.getPrice to handle price.
 * @param {Function} root0.onClose to close component.
 * @returns {React.ReactElement} jsx for the International Shipping selection and Delivery Calender.
 */
function CheckoutInternationalDeliveryChangeDesktop({ onSelect, giftItem, getPrice, onClose }) {
  const sliderRef = useRef(null);
  const initialDate = sanitizeDateStringToDate(giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate) || null;
  const [open, setOpen] = useState(true);
  const [date, setDate] = useState(initialDate);
  const [showMultiOrderModal, setShowMultiOrderModal] = useState(false);
  const internationalShippingTypes = useSelector((state) => state.checkout?.internationalShippingTypes);
  const internationalDeliveryDates = useSelector((state) => state.checkout?.internationalDeliveryDates);
  const shippingDatesAndMethodsLoader = useSelector((state) => state.checkout?.shippingDatesAndMethodsLoader);
  const deliveryDatesLoader = useSelector((state) => state.checkout?.deliveryDatesLoader);
  const { deliveryDates } = internationalDeliveryDates;
  const { shippingDetails, isShippingDetailsExists } = internationalShippingTypes;
  const priceSurgeDetails = useSelector((state) => state.checkout?.priceSurgeDetails);
  const productPriceByDate = useSelector((state) => state.productDetail?.productPriceByDate);
  const currency = useSelector((state) => state.appData?.selectedCurrency);
  const currencyCode = currency === "INR" ? "₹" : currency;
  const [selectedSlotByUser, setSelectedSlotByUser] = useState({});
  const [userSelectedDate, setUserSelectedDate] = useState(null);
  const [calenderScreen, setCalenderScreen] = useState(false);
  const [deliverySlot, setDeliverySlot] = useState(false);
  const [deliveryMethod, setDeliveryMethodScreen] = useState(true);
  const [numberOfIntTimeSlot, setNumberOfIntTimeSlot] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    removeItemBasedOnKey("selectedDates");
    removeItemBasedOnKey("selectedDate");
    const isInternational = checkIsInternationalProduct(giftItem?.mainproduct);
    const payload = {
      pincode: giftItem?.mainproduct?.pincode,
      productId: giftItem?.mainproduct?.productId,
      isInternational,
    };
    dispatch({ type: CHECKOUT_CONSTANTS.GET_SHIPPING_AND_DELIVERY_DATES, payload });
    dispatch({ type: CHECKOUT_CONSTANTS.GET_INTL_SHIPPING, payload });
  }, [dispatch, giftItem.mainproduct, giftItem.mainproduct.pincode, giftItem.mainproduct.productId]);

  /**
   * This method is used to handle close delivery info screen
   *
   */
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  /**
   * This method is used to update the cartItem
   *
   * @param {object} selectedDate date.
   */
  const updateCart = (newSelectedSlot, selectedDate) => {
    const { slot, shipmentMethodId } = selectedSlotByUser;
    const data = {
      deliveryDate: dateToDMY(new Date(selectedDate)),
      itemIndex: giftItem?.cartItemIndex,
      shippingMethodId: shipmentMethodId,
      shippingPrice: slot.shippingPrice,
      timeSlotId: newSelectedSlot?.timeSlotId,
    };
    onSelect(data);
  };

  /**
   * This method is used to select date select
   *
   * @param {object} selectedDate date object.
   */
  function handleDateSelect(selectedDate) {
    setDate(selectedDate);
    setCalenderScreen(false);
    setDeliverySlot(true);
  }

  /**
   * This method is used to select time slot
   *
   * @param {object} selectedSlot selected time slot object.
   */
  function handleTimeSlotSelect(selectedSlot) {
    const payload = {
      pincode: giftItem?.mainproduct?.pincode,
      productId: giftItem?.mainproduct?.productId,
      timeSlotId: selectedSlot?.slot?.timeSlotId,
      shipmentType: selectedSlot?.shipmentMethodId,
    };
    if (deliveryMethod) {
      setDeliveryMethodScreen(false);
      setCalenderScreen(true);
      dispatch({ type: CHECKOUT_CONSTANTS.GET_INTERNATIONAL_DELIVERY_DATES, payload });
    }
    setSelectedSlotByUser(selectedSlot);
    if (deliverySlot) {
      setDeliverySlot(false);
      updateCart(selectedSlot, date);
      handleClose();
    }
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    swipe: false,
    touchMove: false,
    className: slickStyles["slick-wrapper-calendar"],
  };

  /**
   * This method handles next slide
   *
   */
  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  /**
   * This method is used to to handle intel shipping method selection
   *
   * @param {object} selectedShipping selected shipping.
   */
  function handleIntlShippingMethod(selectedShipping) {
    const shippingInfo = shippingDetails[selectedShipping.shipmentMethodId];
    setNumberOfIntTimeSlot(shippingInfo?.timeSlots);
    if (selectedShipping.shipmentMethodId === giftItem?.shippingDetails?.shippingMethodId) {
      setUserSelectedDate(giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate);
    } else {
      setUserSelectedDate(null);
    }
    if (shippingInfo?.timeSlots && shippingInfo?.timeSlots?.length) {
      const [slot] = shippingInfo.timeSlots;
      handleTimeSlotSelect({
        shippingMethodName: shippingInfo.shippingMethodName,
        shipmentMethodId: selectedShipping.shipmentMethodId,
        slot,
      });
    }
    gotoNext();
  }

  /**
   * This method handles previous slide
   *
   */
  const gotoPrev = () => {
    if (calenderScreen) {
      setCalenderScreen(false);
      setDeliverySlot(false);
      setDeliveryMethodScreen(true);
    }
    if (deliverySlot) {
      setDeliverySlot(false);
      setDeliveryMethodScreen(false);
      setCalenderScreen(true);
    }
    sliderRef.current.slickPrev();
  };

  /**
   * Handles loading of delivery method and delivery dates screen.
   *
   * @returns {boolean} The appropriate loader value.
   */
  function handleLoader() {
    if (calenderScreen) {
      return deliveryDatesLoader;
    }
    if (deliveryMethod) {
      return shippingDatesAndMethodsLoader;
    }
    return false;
  }

  /**
   * This functional component represents the international shipping slider .
   *
   * @returns {React.Fragment} main jsx for the international calender.
   */
  const internationalProductDelivery = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalDateTimePicker showLoader={handleLoader()} onClose={handleClose}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Slider {...settings} ref={sliderRef}>
            {deliveryMethod && (
              <div>
                <ShippingMethod
                  gotoPrev={gotoPrev}
                  selectedDeliveryDate={date}
                  onSelect={handleIntlShippingMethod}
                  shippingDetails={shippingDetails}
                  isShippingDetailsExists={isShippingDetailsExists}
                  checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
                  getPrice={getPrice}
                  currencyCode={currencyCode}
                  selectedShippingMethodId={giftItem?.shippingDetails?.shippingMethodId}
                />
              </div>
            )}
            {calenderScreen && (
              <div>
                <DeliveryCalender
                  gotoPrev={gotoPrev}
                  showBackButton
                  checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
                  onSelect={handleDateSelect}
                  deliveryDates={deliveryDates}
                  itemDesiredDeliveryDate={userSelectedDate}
                  itemDesiredDeliveryDatesList={
                    giftItem?.isMultiOrderProduct ? giftItem?.shippingDetails?.selectedDatesList?.toString() : ""
                  }
                  productDetail={{
                    product: { ...giftItem?.mainproduct, isMultiOrderProduct: false, multiOrderProductDetails: {} },
                    priceSurgeDetails,
                    productPriceByDate,
                  }}
                  getPrice={getPrice}
                  currencyCode={currencyCode}
                  showMultiOrderModal={showMultiOrderModal}
                  setShowMultiOrderModal={setShowMultiOrderModal}
                />
              </div>
            )}
            {deliverySlot && (
              <div>
                <TimeSlot
                  gotoPrev={gotoPrev}
                  shippingMethod={shippingDetails}
                  shippingMethodId={selectedSlotByUser?.shipmentMethodId}
                  isShippingDetailsExists={isShippingDetailsExists}
                  selectedDeliveryDate={date}
                  onSelect={handleTimeSlotSelect}
                  checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
                  getPrice={getPrice}
                  currencyCode={currencyCode}
                  selectedCurrency={selectedSlotByUser?.slot?.shippingPrice}
                  selectedShippingMethodName={selectedSlotByUser?.shippingMethodName}
                  numberOfIntTimeSlot={numberOfIntTimeSlot}
                />
              </div>
            )}
          </Slider>
        </ModalDateTimePicker>
      </Modal>
    );
  };

  return <>{internationalProductDelivery()}</>;
}

CheckoutInternationalDeliveryChangeDesktop.propTypes = {
  onSelect: PropTypes.func.isRequired,
  giftItem: PropTypes.objectOf(PropTypes.any).isRequired,
  getPrice: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CheckoutInternationalDeliveryChangeDesktop;
