import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { dateToDMY, sanitizeDateStringToDate } from "../../src/utils/common";
import ModalFullscreenAnimated from "../mobile/pdp/modal-fullscreen-animated";
import autoSuggestStyles from "../mobile/pdp/style/autosuggest.module.scss";
import CHECKOUT_CONSTANTS from "../../src/action-constants/checkout-constants";
import { textConst } from "../../const/text-english";
import { removeItemBasedOnKey } from "../../src/utils/search-utils";
import { checkIsInternationalProduct } from "../../src/utils/checkout";

const DeliveryOnCalender = dynamic(() => import("../mobile/pdp/product-delivery/deliver-on/calender"), { ssr: false });
const SelectDeliveryTimeSlot = dynamic(
  () => import("../mobile/pdp/product-delivery/deliver-on/select-delivery-time-slot"),
  { ssr: false },
);

/**
 * This method is used to render International Shipping selection and Delivery Calender
 *
 * @param {object} root0 it includes object from parent component
 * @param {Function} root0.onSelect to handle selection.
 * @param {object} root0.giftItem Gift item
 * @param {Function} root0.getPrice to handle price.
 * @param {Function} root0.onClose to close component.
 * @returns {React.ReactElement} jsx for the International Shipping selection and Delivery Calender.
 */
function CheckoutInternationalDeliver({ onSelect, giftItem, getPrice, onClose }) {
  const initialDate = sanitizeDateStringToDate(giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate) || null;
  const [open, setOpen] = useState(true);
  const [date, setDate] = useState(initialDate);
  const [calenderScreen, setCalenderScreen] = useState(false);
  const [deliverySlot, setDeliverySlotScreen] = useState(false);
  const [deliveryMethod, setDeliveryMethodScreen] = useState(true);
  const internationalShippingTypes = useSelector((state) => state.checkout?.internationalShippingTypes);
  const internationalDeliveryDates = useSelector((state) => state.checkout?.internationalDeliveryDates);
  const shippingDatesAndMethodsLoader = useSelector((state) => state.checkout?.shippingDatesAndMethodsLoader);
  const deliveryDatesLoader = useSelector((state) => state.checkout?.deliveryDatesLoader);
  const { deliveryDates } = internationalDeliveryDates;
  const { shippingDetails, isShippingDetailsExists } = internationalShippingTypes;
  const priceSurgeDetails = useSelector((state) => state.checkout?.priceSurgeDetails);
  const productPriceByDate = useSelector((state) => state.productDetail?.productPriceByDate);
  const currency = useSelector((state) => state.appData?.selectedCurrency);
  const currencyCode = currency === "INR" ? "₹" : currency;
  const [selectedSlotByUser, setSelectedSlotByUser] = useState({});
  const [userSelectedDate, setUserSelectedDate] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    removeItemBasedOnKey("selectedDates");
    removeItemBasedOnKey("selectedDate");
    const isInternational = checkIsInternationalProduct(giftItem?.mainproduct);
    const payload = {
      pincode: giftItem?.mainproduct?.pincode,
      productId: giftItem?.mainproduct?.productId,
      isInternational,
    };
    dispatch({ type: CHECKOUT_CONSTANTS.GET_SHIPPING_AND_DELIVERY_DATES, payload });
    dispatch({ type: CHECKOUT_CONSTANTS.GET_INTL_SHIPPING, payload });
  }, [dispatch, giftItem.mainproduct, giftItem.mainproduct.pincode, giftItem.mainproduct.productId]);

  /**
   * This method is used to handle close delivery info screen
   *
   */
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  /**
   * This method is used to handle back to delivery info screen
   *
   */
  function handleBack() {
    if (calenderScreen) {
      setCalenderScreen(false);
      setDeliveryMethodScreen(true);
      setDeliverySlotScreen(false);
    } else if (deliveryMethod) {
      setCalenderScreen(false);
      setDeliverySlotScreen(false);
      setDeliveryMethodScreen(false);
      handleClose();
    } else {
      setCalenderScreen(true);
      setDeliveryMethodScreen(false);
      setDeliverySlotScreen(false);
    }
  }

  /**
   * This method is used to update the cartItem
   *
   * @param {object} selectedDate date.
   */
  const updateCart = (newSelectedSlot, selectedDate) => {
    const { slot, shipmentMethodId } = selectedSlotByUser;
    const data = {
      deliveryDate: dateToDMY(new Date(selectedDate)),
      itemIndex: giftItem?.cartItemIndex,
      shippingMethodId: shipmentMethodId,
      shippingPrice: slot.shippingPrice,
      timeSlotId: newSelectedSlot?.slot?.timeSlotId,
    };
    onSelect(data);
  };

  /**
   * This method is used to select date select
   *
   * @param {object} selectedDate date object.
   */
  function handleDateSelect(selectedDate) {
    setDate(selectedDate);
    setCalenderScreen(false);
    setDeliverySlotScreen(true);
  }

  /**
   * This method is used to select time slot
   *
   * @param {object} selectedSlot selected time slot object.
   */
  function handleTimeSlotSelect(selectedSlot) {
    if (selectedSlot.shipmentMethodId === giftItem?.shippingDetails?.shippingMethodId) {
      setUserSelectedDate(giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate);
    } else {
      setUserSelectedDate(null);
    }
    const payload = {
      pincode: giftItem?.mainproduct?.pincode,
      productId: giftItem?.mainproduct?.productId,
      timeSlotId: selectedSlot?.slot?.timeSlotId,
      shipmentType: selectedSlot?.shipmentMethodId,
    };
    dispatch({ type: CHECKOUT_CONSTANTS.GET_INTERNATIONAL_DELIVERY_DATES, payload });
    setSelectedSlotByUser(selectedSlot);
    if (deliveryMethod) {
      dispatch({ type: CHECKOUT_CONSTANTS.GET_INTERNATIONAL_DELIVERY_DATES, payload });
      setDeliveryMethodScreen(false);
      setCalenderScreen(true);
    }
    if (deliverySlot) {
      setDeliverySlotScreen(false);
      updateCart(selectedSlot, date);
      handleClose();
    }
  }

  /**
   * Handles header title changes based on screen and delivery method.
   *
   * @returns {string} The appropriate header title.
   */
  function handleHeaderTitle() {
    if (calenderScreen) {
      return textConst.checkout.selectDeliveryDate;
    }
    if (deliveryMethod) {
      return textConst.checkout.selectDeliveryShippingMethod;
    }
    return textConst.checkout.selectDeliveryTimeSlot;
  }

  /**
   * Handles loading of delivery method and delivery dates screen.
   *
   * @returns {boolean} The appropriate loader value.
   */
  function handleLoader() {
    if (calenderScreen) {
      return deliveryDatesLoader;
    }
    if (deliveryMethod) {
      return shippingDatesAndMethodsLoader;
    }
    return false;
  }

  return (
    <div className={`${autoSuggestStyles["deliver-on"]} mt20`}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalFullscreenAnimated
          screen={textConst.checkout.shippingScreen}
          handleHeaderBack={handleBack}
          animated={handleLoader()}
          headerTitle={handleHeaderTitle()}
        >
          {calenderScreen && deliveryDates?.length ? (
            <DeliveryOnCalender
              onSelect={handleDateSelect}
              deliveryDates={deliveryDates}
              productDetail={{
                product: { ...giftItem?.mainproduct, isMultiOrderProduct: false, multiOrderProductDetails: {} },
                priceSurgeDetails,
                productPriceByDate,
              }}
              getPrice={getPrice}
              currencyCode={currencyCode}
              itemDesiredDeliveryDate={userSelectedDate}
            />
          ) : null}
          {(deliverySlot || deliveryMethod) && (
            <SelectDeliveryTimeSlot
              selectedDeliveryDate={date}
              onSelect={handleTimeSlotSelect}
              shippingDetails={shippingDetails}
              isShippingDetailsExists={isShippingDetailsExists}
              currencyCode={currencyCode}
              getPrice={getPrice}
              checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
              shippingMethodId={selectedSlotByUser?.shipmentMethodId}
              deliverySlot={deliverySlot}
              deliveryMethod={deliveryMethod}
            />
          )}
        </ModalFullscreenAnimated>
      </Modal>
    </div>
  );
}

CheckoutInternationalDeliver.propTypes = {
  onSelect: PropTypes.func.isRequired,
  giftItem: PropTypes.objectOf(PropTypes.any).isRequired,
  getPrice: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CheckoutInternationalDeliver;
