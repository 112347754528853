const PaymentsConstants = {
  FETCH_PAYMENT_METHODS: "FETCH_PAYMENT_METHODS",
  FETCH_PAYMENT_METHODS_SUCCESS: "FETCH_PAYMENT_METHODS_SUCCESS",
  FETCH_PAYMENT_METHODS_FAILED: "FETCH_PAYMENT_METHODS_FAILED",
  FETCH_PAYMENT_OPTIONS_METHODS: "FETCH_PAYMENT_OPTIONS_METHODS",
  FETCH_PAYMENT_OPTIONS_METHODS_SUCCESS: "FETCH_PAYMENT_OPTIONS_METHODS_SUCCESS",
  FETCH_PAYMENT_OPTIONS_METHODS_FAILED: "FETCH_PAYMENT_OPTIONS_METHODS_FAILED",
  FETCH_USER_SAVED_CARDS: "FETCH_USER_SAVED_CARDS",
  FETCH_USER_SAVED_CARDS_SUCCESS: "FETCH_USER_SAVED_CARDS_SUCCESS",
  FETCH_USER_SAVED_CARDS_FAILED: "FETCH_USER_SAVED_CARDS_FAILED",
  CHECK_CARD_TYPE: "CHECK_CARD_TYPE",
  CHECK_CARD_TYPE_SUCCESS: "CHECK_CARD_TYPE_SUCCESS",
  CHECK_CARD_TYPE_FAILED: "CHECK_CARD_TYPE_FAILED",
  FETCH_EMI_OPTIONS: "FETCH_EMI_OPTIONS",
  FETCH_EMI_OPTIONS_SUCCESS: "FETCH_EMI_OPTIONS_SUCCESS",
  FETCH_EMI_OPTIONS_FAILED: "FETCH_EMI_OPTIONS_FAILED",
  FETCH_PAYPAL_CURRENCIES: "FETCH_PAYPAL_CURRENCIES",
  FETCH_PAYPAL_CURRENCIES_SUCCESS: "FETCH_PAYPAL_CURRENCIES_SUCCESS",
  FETCH_PAYPAL_CURRENCIES_FAILED: "FETCH_PAYPAL_CURRENCIES_FAILED",
  FETCH_WALLET_DETAILS: "FETCH_WALLET_DETAILS",
  FETCH_WALLET_DETAILS_SUCCESS: "FETCH_WALLET_DETAILS_SUCCESS",
  FETCH_WALLET_DETAILS_FAILED: "FETCH_WALLET_DETAILS_FAILED",
  FETCH_NET_BANKING_LIST: "FETCH_NET_BANKING_LIST",
  FETCH_NET_BANKING_LIST_SUCCESS: "FETCH_NET_BANKING_LIST_SUCCESS",
  FETCH_NET_BANKING_LIST_FAILED: "FETCH_NET_BANKING_LIST_FAILED",
  FETCH_PAYMENT_OPTION_DETAILS: "FETCH_PAYMENT_OPTION_DETAILS",
  FETCH_PAYMENT_OPTION_DETAILS_SUCCESS: "FETCH_PAYMENT_OPTION_DETAILS_SUCCESS",
  FETCH_PAYMENT_OPTION_DETAILS_FAILED: "FETCH_PAYMENT_OPTION_DETAILS_FAILED",
  FETCH_MIN_EMI_AMOUNT: "FETCH_MIN_EMI_AMOUNT",
  FETCH_MIN_EMI_AMOUNT_SUCCESS: "FETCH_MIN_EMI_AMOUNT_SUCCESS",
  FETCH_MIN_EMI_AMOUNT_FAILED: "FETCH_MIN_EMI_AMOUNT_FAILED",
  FETCH_THANK_YOU_PAGE_INFO: "FETCH_THANK_YOU_PAGE_INFO",
  FETCH_THANK_YOU_PAGE_INFO_SUCCESS: "FETCH_THANK_YOU_PAGE_INFO_SUCCESS",
  FETCH_THANK_YOU_PAGE_INFO_FAILED: "FETCH_THANK_YOU_PAGE_INFO_FAILED",
  FETCH_REMINDERS: "FETCH_REMINDERS",
  FETCH_REMINDERS_SUCCESS: "FETCH_REMINDERS_SUCCESS",
  FETCH_REMINDERS_FAILED: "FETCH_REMINDERS_FAILED",
  SAVE_REMINDERS: "SAVE_REMINDERS",
  SAVE_REMINDERS_SUCCESS: "SAVE_REMINDERS_SUCCESS",
  SAVE_REMINDERS_FAILED: "SAVE_REMINDERS_FAILED",
  DELETE_REMINDERS: "DELETE_REMINDERS",
  DELETE_REMINDERS_SUCCESS: "DELETE_REMINDERS_SUCCESS",
  DELETE_REMINDERS_FAILED: "DELETE_REMINDERS_FAILED",
  DELETE_SAVED_CARDS: "DELETE_SAVED_CARDS",
  DELETE_SAVED_CARDS_SUCCESS: "DELETE_SAVED_CARDS_SUCCESS",
  DELETE_SAVED_CARDS_FAILED: "DELETE_SAVED_CARDS_FAILED",
  FETCH_THANK_YOU_PAGE_DETAIL: "FETCH_THANK_YOU_PAGE_DETAIL",
  FETCH_THANK_YOU_PAGE_DETAIL_SUCCESS: "FETCH_THANK_YOU_PAGE_DETAIL_SUCCESS",
  FETCH_THANK_YOU_PAGE_DETAIL_FAILED: "FETCH_THANK_YOU_PAGE_DETAIL_FAILED",
  SAVE_USER_FEEDBACK: "SAVE_USER_FEEDBACK",
  CLEAR_CHECK_CARD_TYPE: "CLEAR_CHECK_CARD_TYPE",
  FETCH_NET_BANKING_OPTIONS: "FETCH_NET_BANKING_OPTIONS",
  FETCH_NET_BANKING_OPTIONS_FAILED: "FETCH_NET_BANKING_OPTIONS_FAILED",
  FETCH_NET_BANKING_OPTIONS_SUCCESS: "FETCH_NET_BANKING_OPTIONS_SUCCESS",
  UPDATE_REDIRECT_PAGE: "UPDATE_REDIRECT_PAGE",
  SAVE_USER_FEEDBACK_SUCCESS: "SAVE_USER_FEEDBACK_SUCCESS",
  GET_SYSTEM_PROPERTY_REQUESTED: "GET_SYSTEM_PROPERTY_REQUESTED",
  GET_SYSTEM_PROPERTY_SUCCESS: "GET_SYSTEM_PROPERTY_SUCCESS",
  GET_SAVED_CARD_SYSTEM_PROPERTY_REQUESTED: "GET_SAVED_CARD_SYSTEM_PROPERTY_REQUESTED",
  GET_SAVED_CARD_SYSTEM_PROPERTY_SUCCESS: "GET_SAVED_CARD_SYSTEM_PROPERTY_SUCCESS",
  FETCH_OCCASION_LIST_REQUESTED: "FETCH_OCCASION_LIST_REQUESTED",
  FETCH_OCCASION_LIST_SUCCESS: "FETCH_OCCASION_LIST_SUCCESS",
  SET_CURRENCY_SELECTED: "SET_CURRENCY_SELECTED",
};

export const AgentUrl = {
  CANCEL_URL: "/ordermgr/control/orderentry",
};

export default PaymentsConstants;
