import { put, takeLatest, call, all, select, fork } from "redux-saga/effects";
import { Router } from "../../routes/routes";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import CHECKOUT_CONSTANTS, { CheckoutRoutes } from "../../src/action-constants/checkout-constants";
import PAYMENT_CONSTANTS from "../../src/action-constants/payment-constants";

import checkoutActions from "../../src/actions/checkout-actions";
import { verifySelectedContactMechIdsWithSavedAddress } from "../../src/utils/checkout";
import { productTypes } from "../../src/utils/pdp-constants";
/**
 *
 * @param {object} cartItem cartItem
 *
 * @returns {string} string url
 */
const getAddonsInfoUrl = (cartItem) =>
  `/control/getAddonsInfo-rj?addProductId=${cartItem?.mainproduct?.productId}&productId=${
    cartItem?.mainproduct?.productId
  }&format=json&isbuynow=${"Yes"}&orderItemSeqId=${cartItem?.orderItemSeqId}&pinCode=${
    cartItem?.mainproduct?.pincode
  }&productType=${cartItem?.mainproduct?.primaryProductCategoryId}&shippingMethod=${
    cartItem?.shippingDetails?.shippingMethodId
  }&timeSlotId=${cartItem?.shippingDetails?.deliveryTimeSlotId}`;

/**
 * @param {Array} cartItems list of cartItems
 */
function* checkAndFetchFrequentAddons(cartItems) {
  if (cartItems) {
    let addonsListResp = yield all(
      cartItems.map((cartItem) => {
        const { giftItem } = cartItem;
        const isNonDigitalProduct = giftItem?.mainproduct?.productTypeId !== productTypes.DIGITAL_GOOD;
        if (!giftItem?.addons?.length && isNonDigitalProduct && !giftItem?.isMultiOrderProduct) {
          const queryUrl = getAddonsInfoUrl(giftItem);

          return call(checkoutActions.getAddonsInfo, queryUrl);
        }
        return null;
      }),
    );

    addonsListResp = addonsListResp?.filter((item) => item !== null)?.map((item) => item?.data);
    yield put({
      type: CHECKOUT_CONSTANTS.FREQUENTLY_BOUGHT_ADDONS_SUCCESS,
      payload: { frequentlyBoughtAddons: addonsListResp },
    });
  }
}

/**
 * this function get the checkout related data
 *
 * @returns {object} checkout page data cartItems details,sender details,t&c etc.,
 */
function* fetchCheckoutData() {
  const cartItems = yield call(checkoutActions.getCompleteCartItemDetails);
  const [
    cartExpiry,
    coupons,
    senderInfo,
    occasionsAndTemplateMsgs,
    cartSummaryAmountDetail,
    encodedCustomerDetails,
    donationEnable,
    donationAmount,
  ] = yield all([
    call(checkoutActions.getCartExpiry),
    call(checkoutActions.getCoupons),
    call(checkoutActions.getCurrentSenderInfo),
    call(checkoutActions.getOccasionsAndTemplateMsgs),
    call(checkoutActions.getCartSummaryAmountDetail),
    call(checkoutActions.getEncodedCustomerDetails),
    call(checkoutActions.getDonationEnable),
    call(checkoutActions.getDonationAmount),
    call(checkAndFetchFrequentAddons, cartItems?.data?.orderList),
  ]);
  return {
    cartExpiry,
    coupons,
    cartItems,
    senderInfo,
    occasionsAndTemplateMsgs,
    cartSummaryAmountDetail,
    encodedCustomerDetails,
    donationEnable,
    donationAmount,
  };
}

/**
 * This method is initiating all the Checkout Api calls
 *
 * @param {object} action action param
 *
 */
export function* initiateCheckoutSaga(action) {
  try {
    const initiateCheckoutResponse = yield call(fetchCheckoutData);

    if (initiateCheckoutResponse?.error) {
      throw new Error(initiateCheckoutResponse?.error);
    }
    yield put({
      type: CHECKOUT_CONSTANTS.CART_ITEM_EXPIRED,
      payload: null,
    });
    if (initiateCheckoutResponse.cartItems.data.orderList.length === 0) {
      yield put({
        type: CHECKOUT_CONSTANTS.INITIATE_CHECKOUT_SUCCESS,
        payload: {
          cartExpiry: [],
          coupons: [],
          cartItems: [],
          senderInfo: [],
          priceSurgeDetails: [],
          occasionsAndTemplateMsgs: [],
          cartSummaryAmountDetail: [],
          encodedCustomerDetails: [],
          donationAmount: {},
          donationEnable: {},
        },
      });
    } else {
      if (action?.payload?.isMobile) {
        const updateAddressGiftItem = initiateCheckoutResponse.cartItems?.data.orderList?.find(
          (order) =>
            order?.giftItem?.selectedAddressDetails?.selectedContactMechId === "newaddress" ||
            (order?.giftItem?.countryGeoId === "DEU" && !order?.giftItem?.selectedAddressDetails?.doorBell),
        );
        const updateAddressCartIndexState = yield select((store) => store?.checkout?.updateAddressCartIndex);
        if (updateAddressGiftItem && updateAddressCartIndexState !== updateAddressGiftItem?.giftItem?.cartItemIndex) {
          if (updateAddressGiftItem.giftItem?.selectedAddressDetails.selectedContactMechId === "newaddress") {
            yield Router.push(
              {
                pathname: `/${CheckoutRoutes.CHECKOUT}`,
                query: {
                  slug1: CheckoutRoutes.ADD_NEW_ADDRESS,
                  cartItemIndex: updateAddressGiftItem.giftItem.cartItemIndex,
                },
              },
              `/${CheckoutRoutes.CHECKOUT}/${CheckoutRoutes.ADD_NEW_ADDRESS}?cartItemIndex=${updateAddressGiftItem.giftItem.cartItemIndex}`,
            );
          } else if (!updateAddressGiftItem?.giftItem?.selectedAddressDetails?.doorBell) {
            yield Router.push(
              {
                pathname: `/${CheckoutRoutes.CHECKOUT}`,
                query: {
                  slug1: CheckoutRoutes.EDIT_DELIVERY,
                  cartItemIndex: updateAddressGiftItem.giftItem.cartItemIndex,
                  contactMechId: updateAddressGiftItem.giftItem.selectedAddressDetails.selectedContactMechId,
                },
              },
              `/${CheckoutRoutes.CHECKOUT}/${CheckoutRoutes.EDIT_DELIVERY}?cartItemIndex=${updateAddressGiftItem.giftItem.cartItemIndex}&contactMechId=${updateAddressGiftItem.giftItem.selectedAddressDetails.selectedContactMechId}`,
            );
          }
          yield put({
            type: CHECKOUT_CONSTANTS.SET_UPDATE_ADDRESS_CART_INDEX,
            payload: {
              updateAddressCartIndex: updateAddressGiftItem?.giftItem?.cartItemIndex,
            },
          });
        }
      } else if (initiateCheckoutResponse?.cartItems?.data?.orderList) {
        const selectedContactMechIdObj = {};
        initiateCheckoutResponse?.cartItems?.data?.orderList?.map((item) => {
          selectedContactMechIdObj[item?.giftItem?.giftIndex] =
            item?.giftItem?.selectedAddressDetails?.selectedContactMechId;

          return null;
        });
        yield put({
          type: CHECKOUT_CONSTANTS.SET_GIFT_ITEM_DEFAULT_ADDRESS,
          payload: selectedContactMechIdObj,
        });
        yield put({
          type: APP_CONSTANTS.GET_DATE_RANGE_ENABLE,
        });
      }
      yield put({
        type: CHECKOUT_CONSTANTS.INITIATE_CHECKOUT_SUCCESS,
        payload: {
          cartExpiry: initiateCheckoutResponse.cartExpiry.data.details,
          coupons: initiateCheckoutResponse.coupons.data,
          cartItems: initiateCheckoutResponse.cartItems.data,
          senderInfo: initiateCheckoutResponse.senderInfo.data.result,
          occasionsAndTemplateMsgs: initiateCheckoutResponse.occasionsAndTemplateMsgs.data.data,
          cartSummaryAmountDetail: initiateCheckoutResponse.cartSummaryAmountDetail.data,
          encodedCustomerDetails: initiateCheckoutResponse.encodedCustomerDetails.data,
          donationAmount: initiateCheckoutResponse.donationAmount.data,
          donationEnable: initiateCheckoutResponse.donationEnable.data,
        },
      });

      const cakeMessages = [];
      initiateCheckoutResponse?.cartItems?.data?.orderList?.map((item) => {
        if (item?.giftItem?.showCakeMsgBox) {
          cakeMessages.push({
            attrName: "ITEM_MESSAGE",
            attrValue: item?.giftItem?.messageOnCake?.itemMessage,
            itemIndex: item?.giftItem?.cartItemIndex,
          });
        }
        return null;
      });
      yield put({ type: CHECKOUT_CONSTANTS.SET_ITEM_ATTRIBUTES_FOR_ORDER_SUCCESS, payload: cakeMessages });

      if (initiateCheckoutResponse?.senderInfo?.data?.result) {
        const senderData = initiateCheckoutResponse?.senderInfo?.data?.result;
        const orderLst = initiateCheckoutResponse?.cartItems?.data?.orderList;
        const firstOrderDetails = orderLst && orderLst[0]?.giftItem;
        const selectedAddressDetails = firstOrderDetails?.selectedAddressDetails;
        const postalDtlsOfContactMechIdCity =
          selectedAddressDetails?.postalDtlsOfContactMechIdCity &&
          selectedAddressDetails?.postalDtlsOfContactMechIdCity !== "_NA_"
            ? `${selectedAddressDetails?.postalDtlsOfContactMechIdCity}, ${selectedAddressDetails?.countryGeoId}`
            : "";
        yield put({
          type: CHECKOUT_CONSTANTS.UPDATE_SENDER_INFO,
          payload: {
            ...senderData,
            makeProfileCity: "N",
            sCity: senderData.senderCity ? senderData.senderCity : postalDtlsOfContactMechIdCity,
            isRecipientCity: (senderData.senderCity && false) || true,
          },
        });
      }
    }
    if (initiateCheckoutResponse?.cartExpiry?.data?.details?.expiryInSec === 0) {
      yield put({
        type: CHECKOUT_CONSTANTS.CART_ITEM_EXPIRED,
        payload: initiateCheckoutResponse?.cartExpiry?.data?.details?.cartItemIndex,
      });
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.INITIATE_CHECKOUT_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 * This method is initiating all the Updated cart  Api calls
 */
export function* initiateUpdatedCartDataSaga() {
  try {
    const initiateCheckoutResponse = yield call(checkoutActions.initiateCartApis);
    if (initiateCheckoutResponse?.error) {
      throw new Error(initiateCheckoutResponse?.error);
    }
    yield put({
      type: CHECKOUT_CONSTANTS.CART_ITEM_EXPIRED,
      payload: null,
    });
    if (initiateCheckoutResponse.cartItems.data.orderList.length === 0) {
      yield put({
        type: CHECKOUT_CONSTANTS.INITIATE_CHECKOUT_UPDATED_CART_SUCCESS,
        payload: {
          cartExpiry: {},
          cartItems: {},
          viewCart: {},
          cartSummaryAmountDetail: {},
        },
      });
    } else {
      yield put({
        type: APP_CONSTANTS.GET_DATE_RANGE_DATA,
        payload: { orderDeliveryDetails: initiateCheckoutResponse.cartItems.data, type: "checkout" },
      });
      yield put({
        type: CHECKOUT_CONSTANTS.INITIATE_CHECKOUT_UPDATED_CART_SUCCESS,
        payload: {
          cartExpiry: initiateCheckoutResponse.cartExpiry.data.details,
          cartItems: initiateCheckoutResponse.cartItems.data,
          viewCart: initiateCheckoutResponse.viewCart.data,
          cartSummaryAmountDetail: initiateCheckoutResponse.cartSummaryAmountDetail.data,
        },
      });
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.INITIATE_CHECKOUT_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is initiating all the Add New Address Api calls */
export function* initiateAddNewAddressSaga() {
  try {
    const cartItemIndex = window.localStorage.getItem("cartItemIndex");
    const addressUrl = `/control/getAddressDetails-rj?cartItemIndex=${cartItemIndex}`;
    const savedAddressForPinCodeUrl = `/control/getSavedAddressForPincode-rj?cartItemIndex=${cartItemIndex}`;
    const initiateAddressResponse = yield call(checkoutActions.initiateAddNewAddressApis, {
      addressUrl,
      savedAddressForPinCodeUrl,
    });

    if (initiateAddressResponse?.error) {
      throw new Error(initiateAddressResponse?.error);
    }

    yield put({
      type: CHECKOUT_CONSTANTS.INITIATE_ADD_NEW_ADDRESS_SUCCESS,
      payload: {
        addressDetails: initiateAddressResponse.addressDetails?.data.addressdetails,
        cartItems: initiateAddressResponse.cartItems.data,
        savedAddressForPinCode: initiateAddressResponse.savedAddressForPinCode.data.addressList,
      },
    });
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.INITIATE_ADD_NEW_ADDRESS_FAILED,
      payload: { error: error?.errMsg || "Something Went Wrong!" },
    });
  }
}

/** This method all the occasion and template messages */
export function* fetchMessageCardMobileSaga() {
  try {
    const cartItemIndex = window.localStorage.getItem("cartItemIndex");
    const response = yield all([
      call(checkoutActions.getOccasionsAndTemplateMsgs),
      call(checkoutActions.getCardMessageDetails, cartItemIndex),
    ]);

    if (response[0]?.data?.isSuccess && response[1]?.data?.isSuccess) {
      yield put({
        type: CHECKOUT_CONSTANTS.FETCH_MESSAGE_CARD_MOBILE_SUCCESS,
        payload: {
          occasionsAndTemplateMsgs: response[0].data.data,
          getCardMessageDetails: response[1].data.cardDetails,
        },
      });
    } else {
      throw new Error(response[0]?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.FETCH_MESSAGE_CARD_MOBILE_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 *
 * @param {object} action action param
 */
export function* getCardMessageDetailsSaga(action) {
  try {
    const response = yield call(checkoutActions.getCardMessageDetails, action.payload.cartItemIndex);

    if (response?.data?.isSuccess) {
      yield put({
        type: CHECKOUT_CONSTANTS.GET_CARD_MESSAGE_DETAILS_SUCCESS,
        payload: { getCardMessageDetails: response.data.cardDetails },
      });
    } else {
      throw new Error(response[0]?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.GET_CARD_MESSAGE_DETAILS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 *
 * @param {object} action action param
 */
export function* setItemAttributesFromMobileSaga(action) {
  try {
    const response = yield call(checkoutActions.setItemAttributesFromMobile, action.payload.data);

    if (response.status === 200) {
      if (action.payload.isMobile) {
        Router.pushRoute(`/${CheckoutRoutes.CHECKOUT}/${CheckoutRoutes.DELIVERY_ADDRESS}`);
      } else {
        yield put({
          type: CHECKOUT_CONSTANTS.GET_CARD_MESSAGE_DETAILS,
          payload: { cartItemIndex: action.payload.data.cartItemIndex },
        });
        yield put({ type: CHECKOUT_CONSTANTS.FETCH_COMPLETE_CART });
        yield put({ type: CHECKOUT_CONSTANTS.FETCH_CART_EXPIRY });
      }
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.SET_ITEM_ATTRIBUTES_MOBILE_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 *
 * @param {object} action action param
 */
export function* updateAddonQuantitySaga(action) {
  try {
    const response = yield call(checkoutActions.updateAddonQuantity, action.payload.addonData);

    if (response?.data?.isSuccess) {
      const cartResponse = yield all([
        call(checkoutActions.getCartExpiry),
        call(checkoutActions.getCompleteCartItemDetails),
        call(checkoutActions.getCartSummaryAmountDetail),
      ]);

      if (cartResponse[0]?.data?.isSuccess && cartResponse[1]?.data?.isSuccess && cartResponse[2]?.data?.isSuccess) {
        yield put({ type: CHECKOUT_CONSTANTS.FETCH_CART_EXPIRY_SUCCESS, payload: { data: cartResponse[0]?.data } });
        yield put({ type: CHECKOUT_CONSTANTS.FETCH_COMPLETE_CART_SUCCESS, payload: { data: cartResponse[1]?.data } });
        yield put({
          type: CHECKOUT_CONSTANTS.FETCH_CART_SUMMARY_AMOUNT_SUCCESS,
          payload: { data: cartResponse[2]?.data },
        });
        yield put({ type: CHECKOUT_CONSTANTS.UPDATE_ADDON_QTY_SUCCESS });
      } else {
        throw new Error(cartResponse[0]?.data?.errorMsg);
      }
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.UPDATE_ADDON_QTY_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 *
 * @param {object} action action param
 */
export function* updateCartFromCheckoutPageSaga(action) {
  try {
    const response = yield call(checkoutActions.updateCartFromCheckoutPage, action.payload);
    const cartSummaryAmountResponse = yield call(checkoutActions.getCartSummaryAmountDetail);

    if (response?.data?.isSuccess) {
      yield put({
        type: CHECKOUT_CONSTANTS.UPDATE_CART_FROM_CHECKOUT_PAGE_SUCCESS,
        payload: {
          data: response?.data,
          cartSummaryAmountDetail: cartSummaryAmountResponse?.data,
        },
      });
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.UPDATE_CART_FROM_CHECKOUT_PAGE_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 *
 * @param {object} action action param
 */
export function* setItemAttributeForOrderSaga(action) {
  try {
    const response = yield call(checkoutActions.setItemAttributeForOrderItem, action.payload);

    if (response?.data?.isSuccess) {
      if (action.payload.attrName === "sTel") {
        yield put({
          type: CHECKOUT_CONSTANTS.UPDATE_SENDER_CONTACT_NUMBER,
          payload: {
            primaryContactNumber: response?.data?.attrValue,
          },
        });
      } else {
        // TODO: Refactor cakeMessages state implementation, can update in reducer
        const { cakeMessages, cartItems } = yield select((store) => store.checkout);
        let updateCakeMessages = [...cakeMessages];
        const orderDeliveryDetails = cartItems;
        const isExistingEntry = cakeMessages.findIndex((i) => i.itemIndex === response?.data.itemIndex);

        if (isExistingEntry === -1) {
          updateCakeMessages = [...updateCakeMessages, ...[response?.data]];
        } else {
          updateCakeMessages[isExistingEntry] = response?.data;
        }

        // Updating the cake msg in cart items
        const updatedOrderList = cartItems?.orderList?.map((value) => {
          if (value.giftItem.cartItemIndex === action.payload.itemIndex) {
            const orderData = value;
            orderData.giftItem.messageOnCake.itemMessage = action.payload.attrValue;
          }
          return value;
        });

        orderDeliveryDetails.orderList = updatedOrderList;
        yield put({
          type: CHECKOUT_CONSTANTS.SET_ITEM_ATTRIBUTES_FOR_ORDER_SUCCESS,
          payload: updateCakeMessages,
        });
      }
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.SET_ITEM_ATTRIBUTES_FOR_ORDER_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 *
 * @param {object} action action param
 */
export function* setShippingInstructions(action) {
  const { cartItems } = yield select((store) => store.checkout);

  const orderDeliveryDetails = cartItems;

  orderDeliveryDetails?.orderList?.map((value) => {
    if (value?.giftItem?.cartItemIndex === action?.payload?.cartItemIndex) {
      const orderData = value;
      orderData.giftItem.shippingInstructions = action.payload.shippingInstruction;
    }
    return value;
  });

  yield put({
    type: CHECKOUT_CONSTANTS.SET_SHIPPING_INSTRUCTIONS_SUCCESS,
    payload: orderDeliveryDetails,
  });
}

/**
 *
 * @param {object} action action param
 */
export function* setAddressForItemSaga(action) {
  try {
    const response = yield call(checkoutActions.setAddressForItem, action.payload.data);

    if (response?.data?.isSuccess) {
      yield put({
        type: CHECKOUT_CONSTANTS.SET_ADDRESS_FOR_ITEM_SUCCESS,
        payload: [response?.data],
      });
      if (action.payload.isMobile) {
        Router.pushRoute(`${CheckoutRoutes.CHECKOUT}/${CheckoutRoutes.DELIVERY_ADDRESS}`);
      } else {
        const cartResponse = yield all([
          call(checkoutActions.getCartExpiry),
          call(checkoutActions.getCompleteCartItemDetails),
        ]);

        if (cartResponse[1]?.data?.isSuccess) {
          yield put({ type: CHECKOUT_CONSTANTS.FETCH_CART_EXPIRY_SUCCESS, payload: { data: cartResponse[0]?.data } });
          yield put({ type: CHECKOUT_CONSTANTS.FETCH_COMPLETE_CART_SUCCESS, payload: { data: cartResponse[1]?.data } });
          yield put({ type: CHECKOUT_CONSTANTS.SET_ADDRESS_DETAILS, payload: null });
        } else {
          throw new Error(cartResponse[0]?.data?.errorMsg);
        }
      }
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.SET_ADDRESS_FOR_ITEM_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 *
 * @param {object} action action param
 */
export function* deleteItemAddressSaga(action) {
  try {
    const response = yield call(checkoutActions.deleteItemAddress, action.payload);

    if (response?.data?.isSuccess) {
      const cartResponse = yield all([
        call(checkoutActions.getCartExpiry),
        call(checkoutActions.getCompleteCartItemDetails),
      ]);

      if (cartResponse[1]?.data?.isSuccess) {
        yield put({ type: CHECKOUT_CONSTANTS.FETCH_CART_EXPIRY_SUCCESS, payload: { data: cartResponse[0]?.data } });
        yield put({ type: CHECKOUT_CONSTANTS.FETCH_COMPLETE_CART_SUCCESS, payload: { data: cartResponse[1]?.data } });

        yield put({
          type: CHECKOUT_CONSTANTS.DELETE_ITEM_ADDRESS_SUCCESS,
          payload: [response?.data],
        });
        // updating the selected saved address on selected address
        const { contactMechId } = action.payload;
        let selectedContactMechIdList = yield select((store) => store.checkout.selectedContactMechIdList);
        if (selectedContactMechIdList && contactMechId) {
          // again verifying the address ids in the saved address list in deleted or edited case
          selectedContactMechIdList = verifySelectedContactMechIdsWithSavedAddress(
            selectedContactMechIdList,
            cartResponse[1]?.data?.orderList,
          );
          yield put({
            type: CHECKOUT_CONSTANTS.SET_GIFT_ITEM_DEFAULT_ADDRESS,
            payload: selectedContactMechIdList,
          });
        }
      } else {
        throw new Error(cartResponse[0]?.data?.errorMsg);
      }
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.DELETE_ITEM_ADDRESS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 *
 * @param {object} action action param
 */
export function* applyCouponSaga(action) {
  try {
    const response = yield call(checkoutActions.applyCoupon, action.payload.coupon || action.payload);

    if (response?.data?.isSuccess) {
      yield put({
        type: CHECKOUT_CONSTANTS.APPLY_COUPON_SUCCESS,
        payload: { data: response?.data, isModelOpen: action.payload.isModel },
      });
      yield put({
        type: CHECKOUT_CONSTANTS.COUPON_APPLIED,
        payload: true,
      });
    } else {
      yield put({
        type: CHECKOUT_CONSTANTS.APPLY_COUPON_FAILED,
        payload: { error: response?.data?.errorMsg || "Something Went Wrong!" },
      });
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.APPLY_COUPON_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 *
 * @param {object} action action param
 */
export function* removeCouponSaga(action) {
  try {
    const response = yield call(checkoutActions.removeCoupon, action.payload);
    yield put({
      type: CHECKOUT_CONSTANTS.REMOVE_COUPON_SUCCESS,
      payload: { data: response?.data },
    });
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.REMOVE_COUPON_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 * update cake Box data
 *
 * @param {object} response - api response
 * @param {object} payloadData - cart index
 */
function* updateCakeBox(response, payloadData) {
  const checkout = yield select((store) => store.checkout);
  if (checkout?.cartItems) {
    const orderDeliveryDetails = checkout.cartItems;
    const payloadItemSeq = `${payloadData.masterProductId}_${payloadData.orderItemSeqId.slice(2)}`;
    const { showCakeMsgBox, productItemIndexAssoc } = response.data;

    const updatedOrderList = checkout.cartItems?.orderList?.map((value) => {
      const { orderItemSeqId } = value.giftItem;
      const mainProductId = value.giftItem.mainproduct.productId;
      const mainProductIdentifier = `${mainProductId}_${orderItemSeqId}`;
      const orderData = value;

      if (value.giftItem.addons) {
        orderData.giftItem.addons = value.giftItem.addons.map((addon) => {
          const currentAddon = addon;
          const addonIdentifier = `${addon.productId}_${orderItemSeqId}`;
          if (productItemIndexAssoc[addonIdentifier])
            currentAddon.itemIndex = Number(productItemIndexAssoc[addonIdentifier]);
          return addon;
        });
      }

      if (productItemIndexAssoc[mainProductIdentifier]) {
        orderData.giftItem.cartItemIndex = Number(productItemIndexAssoc[mainProductIdentifier]);
      }

      if (mainProductIdentifier === payloadItemSeq) {
        if (response.data.cakeMsgMaxLength) orderData.giftItem.cakeMsgMaxLength = response.data.cakeMsgMaxLength;
        orderData.giftItem.showCakeMsgBox = showCakeMsgBox;
        if (!showCakeMsgBox) orderData.giftItem.messageOnCake = { itemMessage: "" };
      }
      return value;
    });

    orderDeliveryDetails.orderList = updatedOrderList;
  }
}

/**
 * Add/Update addons from the frequently bought List
 *
 * @param {object} action action param
 */
export function* addAddonsToCartSaga(action) {
  try {
    let response;
    if (action.payload.isFirstAddon) {
      response = yield call(checkoutActions.addAddonsToCart, action.payload.addonData);
    } else {
      response = yield call(checkoutActions.updateAddonQuantity, action.payload.addonData);
    }

    if (response?.data?.isSuccess) {
      const cartResponse = yield call(checkoutActions.getCartSummaryAmountDetail);

      if (action.payload.isFirstAddon) {
        yield* updateCakeBox(response, action.payload.addonData);
      }

      if (cartResponse?.data?.isSuccess) {
        yield put({
          type: CHECKOUT_CONSTANTS.FETCH_CART_SUMMARY_AMOUNT_SUCCESS,
          payload: { data: cartResponse?.data },
        });
        yield put({ type: CHECKOUT_CONSTANTS.ADD_ADDON_TO_CART_SUCCESS });
      } else {
        throw new Error(cartResponse?.data?.errorMsg);
      }
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.ADD_ADDON_TO_CART_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**
 *
 * @param {object} action action param
 */
export function* deleteItemSaga(action) {
  try {
    const response = yield call(checkoutActions.deleteItem, action.payload.url);

    if (response?.data?.isSuccess) {
      if (!action.payload?.isFrequentAddOns) {
        // If deleted from the main checkout page delete button

        const cartResponse = yield all([
          call(checkoutActions.getCartExpiry),
          call(checkoutActions.getCompleteCartItemDetails),
          call(checkoutActions.getCartSummaryAmountDetail),
        ]);

        if (cartResponse[1]?.data?.isSuccess && cartResponse[2]?.data?.isSuccess) {
          yield put({ type: CHECKOUT_CONSTANTS.FETCH_CART_EXPIRY_SUCCESS, payload: { data: cartResponse[0]?.data } });
          yield put({ type: CHECKOUT_CONSTANTS.FETCH_COMPLETE_CART_SUCCESS, payload: { data: cartResponse[1]?.data } });
          yield put({
            type: CHECKOUT_CONSTANTS.FETCH_CART_SUMMARY_AMOUNT_SUCCESS,
            payload: { data: cartResponse[2]?.data },
          });

          if (!action.payload.isGiftItem) {
            yield call(checkAndFetchFrequentAddons, cartResponse[1]?.data.orderList);
          }

          yield put({ type: CHECKOUT_CONSTANTS.DELETE_ITEM_SUCCESS });
        } else {
          throw new Error(cartResponse[0]?.data?.errorMsg);
        }
      } else {
        // If deleted from the frequently bought list

        const cartSummary = yield call(checkoutActions.getCartSummaryAmountDetail);

        yield* updateCakeBox(response, action.payload);

        if (cartSummary?.data?.isSuccess) {
          yield put({
            type: CHECKOUT_CONSTANTS.FETCH_CART_SUMMARY_AMOUNT_SUCCESS,
            payload: { data: cartSummary?.data },
          });

          // If deleted from the remove button in frequently Bought list
          yield put({ type: CHECKOUT_CONSTANTS.DELETE_ITEM_SUCCESS });
        } else {
          throw new Error(cartSummary?.data?.errorMsg);
        }
      }
      const selectedContactMechIdList = yield select((store) => store.checkout.selectedContactMechIdList);
      if (action.payload.isGiftItem && action.payload.giftIndex && selectedContactMechIdList) {
        const updatedSelectedContactMechIdList = {};

        delete selectedContactMechIdList[action.payload.giftIndex];
        Object.entries(selectedContactMechIdList).forEach(([key, value]) => {
          if (key > action.payload.giftIndex) {
            updatedSelectedContactMechIdList[key - 1] = value;
          } else {
            updatedSelectedContactMechIdList[key] = value;
          }
          return null;
        });
        yield put({
          type: CHECKOUT_CONSTANTS.SET_GIFT_ITEM_DEFAULT_ADDRESS,
          payload: updatedSelectedContactMechIdList,
        });
      }
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.DELETE_ITEM_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** Fetch Coupons */
export function* fetchCouponsSaga() {
  try {
    const response = yield call(checkoutActions.getCoupons);

    if (response?.data?.isSuccess) {
      yield put({
        type: CHECKOUT_CONSTANTS.FETCH_COUPONS_SUCCESS,
        payload: { data: response?.data },
      });
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.FETCH_COUPONS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** Fetch Complete Cart Item Details */
export function* fetchCompleteCartSaga() {
  try {
    const response = yield call(checkoutActions.getCompleteCartItemDetails);

    if (response?.data?.isSuccess) {
      yield put({
        type: CHECKOUT_CONSTANTS.FETCH_COMPLETE_CART_SUCCESS,
        payload: { data: response?.data },
      });
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.FETCH_COMPLETE_CART_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** Fetch view Cart Item Details
 *
 */
export function* fetchViewCartSaga() {
  try {
    const response = yield call(checkoutActions.getViewCart);

    if (response?.status === 200) {
      yield put({
        type: CHECKOUT_CONSTANTS.FETCH_VIEW_CART_SUCCESS,
        payload: { data: response?.data },
      });
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.FETCH_VIEW_CART_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** Fetch Cart Expiry */
export function* fetchCartExpirySaga() {
  try {
    const response = yield call(checkoutActions.getCartExpiry);

    if (response?.data?.isSuccess) {
      yield put({
        type: CHECKOUT_CONSTANTS.FETCH_CART_EXPIRY_SUCCESS,
        payload: { data: response?.data.details },
      });
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.FETCH_CART_EXPIRY_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** Fetch Cart Summary Amount DetailS */
export function* fetchCartSummaryAmountSaga() {
  try {
    const response = yield call(checkoutActions.getCartSummaryAmountDetail);

    if (response?.data?.isSuccess) {
      yield put({
        type: CHECKOUT_CONSTANTS.FETCH_CART_SUMMARY_AMOUNT_SUCCESS,
        payload: { data: response?.data },
      });
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.FETCH_CART_SUMMARY_AMOUNT_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** Fetch Salutations */
export function* getSalutationsSaga() {
  try {
    const response = yield call(checkoutActions.getSalutations);

    if (response?.data?.isSuccess) {
      yield put({
        type: CHECKOUT_CONSTANTS.GET_SALUTATIONS_SUCCESS,
        payload: { data: response?.data?.list },
      });
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.GET_SALUTATIONS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** Fetch Special country goeIds */
export function* getSpecialCountriesGeoIdsForAddressSaga() {
  try {
    const response = yield call(checkoutActions.getSpecialCountriesGeoIds);

    if (response?.data?.value) {
      yield put({
        type: CHECKOUT_CONSTANTS.GET_SPL_COUNTRY_GEO_IDS_SUCCESS,
        payload: response?.data.value,
      });
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.GET_SPL_COUNTRY_GEO_IDS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** Fetch GetGrocery Vendor URL and auth key */
export function* getVendorURLsForAddressSaga() {
  try {
    const response = yield call(checkoutActions.getVendorURLs);

    if (response?.data?.apiurl) {
      yield put({
        type: CHECKOUT_CONSTANTS.GET_VENDOR_URLS_SUCCESS,
        payload: response?.data,
      });
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.GET_VENDOR_URLS_FAILED,
      payload: { error: error?.message || "Something went Wrong!" },
    });
  }
}

/** Get surge details
 *
 * @param {object} action obj
 */
export function* getPriceSurgeDetailsByProductIdSaga(action) {
  try {
    const cartData = yield select((store) => store.checkout?.viewCart?.items);
    const filterProduct = cartData.filter((item) => item.isVariant && item.productId === action.payload.productId);
    const productId = filterProduct[0]?.baseProductId || action.payload.productId;

    const surgeResponse = yield call(checkoutActions.getPriceSurgeDetailsByProductId, productId);
    if (surgeResponse?.data) {
      yield put({
        type: CHECKOUT_CONSTANTS.SET_SURGE_PRICE_DETAILS,
        payload: surgeResponse?.data,
      });
    } else {
      throw new Error(surgeResponse?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.GET_SURGE_PRICE_DETAILS_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** Get Shipment types and Dates
 *
 * @param {object} action obj
 */
export function* getDatesAndShippingMethodsSaga(action) {
  try {
    yield put({
      type: CHECKOUT_CONSTANTS.SHIPPING_AND_DELIVERY_DATES_FETCH_START,
    });
    yield put({
      type: CHECKOUT_CONSTANTS.GET_SURGE_PRICE_DETAILS,
      payload: action?.payload,
    });

    const response = yield call(
      checkoutActions.getDatesAndShippingMethods,
      action.payload.pincode,
      action.payload.productId,
      action.payload.isInternational,
    );
    if (response?.data?.message?.trim()?.toLowerCase() === "success") {
      yield put({
        type: CHECKOUT_CONSTANTS.GET_SHIPPING_AND_DELIVERY_DATES_SUCCESS,
        payload: response?.data,
      });
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.GET_SHIPPING_AND_DELIVERY_DATES_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** Get Shipment types for international
 *
 * @param {object} action obj
 */
export function* getShipmentTypesForINTLSaga(action) {
  try {
    const response = yield call(
      checkoutActions.getEarliestShipmentTypesForINTL,
      action.payload.pincode,
      action.payload.productId,
    );
    if (response?.data?.message?.trim()?.toLowerCase() === "success") {
      yield put({
        type: CHECKOUT_CONSTANTS.GET_INTL_SHIPPING_SUCCESS,
        payload: response?.data,
      });
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.GET_INTL_SHIPPING_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}
/** Get IntlDeliveryDates
 *
 * @param {object} action obj
 */
export function* getIntlDeliveryDatesSaga(action) {
  try {
    yield put({
      type: CHECKOUT_CONSTANTS.DELIVERY_DATES_FETCH_START,
    });
    const response = yield call(
      checkoutActions.getIntlDeliveryDates,
      action.payload.pincode,
      action.payload.productId,
      action.payload.shipmentType,
      action.payload.timeSlotId,
    );
    if (response?.data?.message?.trim()?.toLowerCase() === "success") {
      yield put({
        type: CHECKOUT_CONSTANTS.GET_INTERNATIONAL_DELIVERY_DATES_SUCCESS,
        payload: response?.data,
      });
    } else {
      throw new Error(response?.data?.errorMsg || "Something Went Wrong!");
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.GET_INTERNATIONAL_DELIVERY_DATES_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** Apply manual adjustments
 *
 * @param {object} action obj
 */
export function* applyManualAdjSaga(action) {
  try {
    yield call(checkoutActions.applyManualAdjustments, action.payload);
    // TODO: investigate why isSuccess is always false
    // if (response?.data?.isSuccess) {
    yield put({
      type: PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTION_DETAILS,
    });
    yield put({
      type: CHECKOUT_CONSTANTS.FETCH_CART_SUMMARY_AMOUNT,
    });
    // } else {
    //   throw new Error(response?.data?.errorMsg);
    // }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.APPLY_MANUAL_ADJUSTMENT_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**  Remove manual adjustments
 *
 */
export function* removeManualAdjSaga() {
  try {
    const response = yield call(checkoutActions.removeManualAdjustments);
    if (response?.data?.isSuccess) {
      yield put({
        type: PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTION_DETAILS,
      });
      yield put({
        type: CHECKOUT_CONSTANTS.FETCH_CART_SUMMARY_AMOUNT,
      });
    } else {
      throw new Error(response?.data?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.REMOVE_MANUAL_ADJUSTMENT_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**  adding or removing donation
 *
 * @param {object} action obj
 */
export function* addOrRemoveDonationSaga(action) {
  try {
    const response = yield call(checkoutActions.addOrRemoveDonation, action.payload);
    if (response?.isSuccess) {
      yield put({
        type: CHECKOUT_CONSTANTS.DONATION_CHANGE_SUCCESS,
        payload: response,
      });
      yield put({
        type: CHECKOUT_CONSTANTS.FETCH_CART_SUMMARY_AMOUNT,
      });
    } else {
      throw new Error(response?.errorMsg);
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.DONATION_CHANGE_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/**  fetches Donation info
 *
 */
export function* fetchDonationInfoSaga() {
  try {
    const response = yield call(checkoutActions.fetchDonationInfo);
    if (response.status === 200) {
      yield put({
        type: CHECKOUT_CONSTANTS.DONATION_INFO_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: CHECKOUT_CONSTANTS.DONATION_INFO_FAILED,
      payload: { error: error?.message || "Something Went Wrong!" },
    });
  }
}

/** This method is used to yield success response of INITIATE_CHECKOUT action */
function* watchInitiateCheckoutRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.INITIATE_CHECKOUT, initiateCheckoutSaga);
}

/** This method is used to yield success response of INITIATE_CHECKOUT_UPDATED_CART action */
function* watchGetUpdatedCartDetailsRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.INITIATE_CHECKOUT_UPDATED_CART, initiateUpdatedCartDataSaga);
}

/** This method is used to yield success response of FETCH_MESSAGE_CARD_MOBILE action */
function* watchMessageCardMobileRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.FETCH_MESSAGE_CARD_MOBILE, fetchMessageCardMobileSaga);
}

/** This method is used to yield success response of SET_ITEM_ATTRIBUTES_MOBILE action */
function* watchSetItemAttributesFromMobileRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.SET_ITEM_ATTRIBUTES_MOBILE, setItemAttributesFromMobileSaga);
}

/** This method is used to yield success response of UPDATE_ADDON_QTY action */
function* watchUpdateAddonQuantityRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.UPDATE_ADDON_QTY, updateAddonQuantitySaga);
}

/** This method is used to yield success response of APPLY_COUPON action */
function* watchApplyCouponRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.APPLY_COUPON, applyCouponSaga);
}

/** This method is used to yield success response of REMOVE_COUPON action */
function* watchRemoveCouponRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.REMOVE_COUPON, removeCouponSaga);
}

/** This method is used to yield success response of FETCH_COUPONS action */
function* watchFetchCouponsRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.FETCH_COUPONS, fetchCouponsSaga);
}

/** This method is used to yield success response of FETCH_CART_EXPIRY action */
function* watchFetchCartExpiryRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.FETCH_CART_EXPIRY, fetchCartExpirySaga);
}

/** This method is used to yield success response of FETCH_COMPLETE_CART action */
function* watchFetchCompleteCartRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.FETCH_COMPLETE_CART, fetchCompleteCartSaga);
}

/** This method is used to yield success response of FETCH_VIEW_CART action */
function* watchFetchViewCartRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.FETCH_VIEW_CART, fetchViewCartSaga);
}

/** This method is used to yield success response of FETCH_CART_SUMMARY_AMOUNT action */
function* watchFetchCartSummaryAmountRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.FETCH_CART_SUMMARY_AMOUNT, fetchCartSummaryAmountSaga);
}

/** This method is used to yield success response of ADD_ADDON_TO_CART action */
function* watchAddAddonsToCartRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.ADD_ADDON_TO_CART, addAddonsToCartSaga);
}

/** This method is used to yield success response of DELETE_ITEM action */
function* watchDeleteItemRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.DELETE_ITEM, deleteItemSaga);
}

/** This method is used to yield success response of GET_CARD_MESSAGE_DETAILS action */
function* watchGetCardMessageDetailsRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.GET_CARD_MESSAGE_DETAILS, getCardMessageDetailsSaga);
}

/** This method is used to yield success response of GET_SALUTATIONS action */
function* watchGetSalutationsRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.GET_SALUTATIONS, getSalutationsSaga);
}

/** This method is used to yield success response of INITIATE_ADD_NEW_ADDRESS action */
function* watchInitiateAddNewAddressRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.INITIATE_ADD_NEW_ADDRESS, initiateAddNewAddressSaga);
}

/** This method is used to yield success response of UPDATE_CART_FROM_CHECKOUT_PAGE action */
function* watchUpdateCartFromCheckoutPageRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.UPDATE_CART_FROM_CHECKOUT_PAGE, updateCartFromCheckoutPageSaga);
}

/** This method is used to yield success response of SET_ITEM_ATTRIBUTES_FOR_ORDER action */
function* watchSetItemAttributeForOrderRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.SET_ITEM_ATTRIBUTES_FOR_ORDER, setItemAttributeForOrderSaga);
}

/** This method is used to yield success response of SET_ADDRESS_FOR_ITEM action */
function* watchSetAddressForItemRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.SET_ADDRESS_FOR_ITEM, setAddressForItemSaga);
}

/** This method is used to yield success response of SET_ADDRESS_FOR_ITEM action */
function* watchDeleteItemAddressRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.DELETE_ITEM_ADDRESS, deleteItemAddressSaga);
}

/** This method is used to yield success response of APPLY_MANUAL_ADJUSTMENT action */
function* watchApplyManualAdjRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.APPLY_MANUAL_ADJUSTMENT, applyManualAdjSaga);
}
/** This method is used to yield success response of REMOVE_MANUAL_ADJUSTMENT action */
function* watchRemoveManualAdjRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.REMOVE_MANUAL_ADJUSTMENT, removeManualAdjSaga);
}
/** This method is used to yield success response of GET_SPL_COUNTRY_GEO_IDS action */
function* watchSpecialCountriesGeoIdsRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.GET_SPL_COUNTRY_GEO_IDS, getSpecialCountriesGeoIdsForAddressSaga);
}
/** This method is used to yield success response of GET_VENDOR_URLS action */
function* watchVendorURLsSagaRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.GET_VENDOR_URLS, getVendorURLsForAddressSaga);
}
/** This method is used to yield success response of GET_SHIPPING_AND_DELIVERY_DATES action */
function* watchDatesAndShippingMethodsSagaRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.GET_SHIPPING_AND_DELIVERY_DATES, getDatesAndShippingMethodsSaga);
}

/** This method is used to yield success response of GET_INTL_SHIPPING action */
function* watchShipmentTypesForINTLSagaRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.GET_INTL_SHIPPING, getShipmentTypesForINTLSaga);
}

/** This method is used to yield success response of GET_INTERNATIONAL_DELIVERY_DATES action */
function* watchIntlDeliveryDatesSagaRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.GET_INTERNATIONAL_DELIVERY_DATES, getIntlDeliveryDatesSaga);
}

/** This method is used to yield success response of GET_INTERNATIONAL_DELIVERY_DATES action */
function* watchSetShippingInstructionsRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.SET_SHIPPING_INSTRUCTIONS, setShippingInstructions);
}

/** This method is used to yield success response of GET_SURGE_PRICE_DETAILS  action */
function* watchGetPriceSurgeDetailsSagaRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.GET_SURGE_PRICE_DETAILS, getPriceSurgeDetailsByProductIdSaga);
}

/** This method is used to yield success response of GET_SURGE_PRICE_DETAILS  action */
function* watchDonationChangeSagaRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.DONATION_CHANGE, addOrRemoveDonationSaga);
}

/** This method is used to yield success response of GET_SURGE_PRICE_DETAILS  action */
function* watchDonationInfoSagaRequest() {
  yield takeLatest(CHECKOUT_CONSTANTS.DONATION_INFO, fetchDonationInfoSaga);
}

/** This method is used to listen to respective dispatched action */
export default function* checkoutSaga() {
  yield fork(watchMessageCardMobileRequest);
  yield fork(watchInitiateCheckoutRequest);
  yield fork(watchSetItemAttributesFromMobileRequest);
  yield fork(watchUpdateAddonQuantityRequest);
  yield fork(watchApplyCouponRequest);
  yield fork(watchRemoveCouponRequest);
  yield fork(watchFetchCouponsRequest);
  yield fork(watchFetchCartExpiryRequest);
  yield fork(watchFetchCompleteCartRequest);
  yield fork(watchFetchCartSummaryAmountRequest);
  yield fork(watchAddAddonsToCartRequest);
  yield fork(watchDeleteItemRequest);
  yield fork(watchGetCardMessageDetailsRequest);
  yield fork(watchGetSalutationsRequest);
  yield fork(watchInitiateAddNewAddressRequest);
  yield fork(watchUpdateCartFromCheckoutPageRequest);
  yield fork(watchSetItemAttributeForOrderRequest);
  yield fork(watchSetAddressForItemRequest);
  yield fork(watchApplyManualAdjRequest);
  yield fork(watchRemoveManualAdjRequest);
  yield fork(watchDeleteItemAddressRequest);
  yield fork(watchFetchViewCartRequest);
  yield fork(watchSpecialCountriesGeoIdsRequest);
  yield fork(watchVendorURLsSagaRequest);
  yield fork(watchDatesAndShippingMethodsSagaRequest);
  yield fork(watchShipmentTypesForINTLSagaRequest);
  yield fork(watchIntlDeliveryDatesSagaRequest);
  yield fork(watchGetUpdatedCartDetailsRequest);
  yield fork(watchSetShippingInstructionsRequest);
  yield fork(watchGetPriceSurgeDetailsSagaRequest);
  yield fork(watchDonationChangeSagaRequest);
  yield fork(watchDonationInfoSagaRequest);
}
