import React from "react";
import { Box, Link, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  homePagelogo: {
    position: "absolute",
    top: "8px",
    left: "12px",
  },
  otherPageLogo: {
    position: "absolute",
    top: "6px",
    left: "44px",
  },
  notfoundPageLogo: {
    position: "absolute",
    top: "6px",
    left: "12px",
  },
  logoSvg: {
    background: "url('/all-icons.svg')",
    backgroundSize: "850%",
    width: "60px",
    height: "30px",
    position: "absolute",
    backgroundPositionX: "-100px",
    backgroundPositionY: "-1px",
  },
}));

/**
 * This component is used to show fnp logo in the m-site header
 *
 * @param {object} root0 props from parent
 * @param {boolean} root0.isHomePage whether the page is home page or not
 * @param {boolean} root0.isNotFoundPage whether the page is a 404 page
 * @param {string} root0.catalogId current catalog id
 * @returns {React.ReactElement} - renders fnp-logo component
 */
const FNPLogo = ({ isHomePage, isNotFoundPage, catalogId }) => {
  const classes = useStyles();

  let logoStyle = isHomePage ? classes.homePagelogo : classes.otherPageLogo;
  if (isNotFoundPage) {
    logoStyle = classes.notfoundPageLogo;
  }
  let homeURL;
  if (catalogId === "india") {
    homeURL = `/`;
  } else {
    homeURL = catalogId === "singapore" ? `/${catalogId}/rakhi` : `/${catalogId}/gifts`;
  }

  return (
    <>
      <Link href={homeURL} className={logoStyle}>
        <Box component="span" className={classes.logoSvg} />
      </Link>
    </>
  );
};
FNPLogo.propTypes = {
  isHomePage: PropTypes.bool,
  isNotFoundPage: PropTypes.bool,
  catalogId: PropTypes.string,
};

FNPLogo.defaultProps = {
  isHomePage: false,
  isNotFoundPage: false,
  catalogId: "india",
};

export default FNPLogo;
