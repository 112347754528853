import React, { useCallback } from "react";
import IconButton from "@material-ui/core/IconButton";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  iconBtnWithTitle: {
    marginRight: theme.spacing(-1),
  },
  badgeIcon: {
    "& > span": {
      fontSize: "8px",
      minWidth: "0px",
      background: theme.color?.red,
      lineHeight: "16px",
      borderRadius: "50%",
      width: "15px",
      height: "15px",
      color: "#fffff",
    },
  },
}));

/**
 * This component is used to show cart icon in the m-site header
 *
 * @param {object} root0 props from parent
 * @param {boolean} root0.withTitle whether page header have title or not
 *
 * @returns {React.ReactElement} - renders cart-icon-btn component
 */
const CartIconBtn = ({ withTitle }) => {
  const classes = useStyles();
  const iconStyle = withTitle ? classes.iconBtnWithTitle : "";

  const userInfo = useSelector((state) => state.userDetails);
  const cartQty = userInfo?.cartInfo?.cartTotalQuantity;

  /**
   * Function navigates to cart preview screen when cart icon is clicked
   */
  const onIconClick = useCallback(() => {
    window.location.href = `${window.location.origin}/cartpreview`;
  }, []);

  return (
    <IconButton
      color="inherit"
      disableRipple
      aria-label="show cart quantity"
      className={iconStyle}
      onClick={onIconClick}
    >
      <Badge title="Added cart list" badgeContent={cartQty} className={classes.badgeIcon}>
        <ShoppingCartIcon />
      </Badge>
    </IconButton>
  );
};

CartIconBtn.propTypes = {
  withTitle: PropTypes.bool,
};

CartIconBtn.defaultProps = {
  withTitle: false,
};

export default CartIconBtn;
