import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import CurrencyDetails from "./currencyDetails";
import DateRangeInfo from "../../../common/date-range-info";
import { getCourierDateRangeFromDates } from "../../../../src/utils/common";
import { productTypes } from "../../../../src/utils/pdp-constants";

const useStyles = makeStyles((theme) => ({
  deliveryDate: { padding: "5px 2px", display: "flex", justifyContent: "space-between" },
  deliveryDateChange: { color: theme.color?.blue, cursor: "pointer" },
  deliveryTime: {
    background: "#f8f8f8",
    padding: "14px 5px",
    borderRadius: 3,
    position: "relative",
    marginBottom: 12,
    margin: 0,
  },
  selectedDate: {
    width: "50%",
    display: "inline-block",
    textAlign: "center",
    fontSize: 40,
    color: theme.color?.darkBlack,
    fontWeight: 300,
    lineHeight: "34px",
    marginTop: 2,
    wordWrap: "normal",
  },
  month: { width: "50%", display: "flex", flexDirection: "column" },
  selectedMonthDay: { float: "left", textTransform: "uppercase", fontSize: 14, display: "block", marginLeft: "7%" },
  dayDate: {
    float: "left",
    width: "30%",
    display: "flex",
    verticalAlign: "top",
    lineHeight: "normal",
  },
  timeDelivery: {
    width: "69%",
    fontSize: 14,
    display: "inline-block",
    verticalAlign: "top",
    lineHeight: "normal",
  },
  selectedDelivery: {},
  selectedTime: {
    display: "block",
    height: 18,
  },
  fnpRed: {
    color: theme.color?.red,
  },
  shippingPriceFree: {
    color: theme.color?.green,
    fontWeight: 500,
    fontSize: 15,
  },
  timeSlotChangeMsg: {
    background: "#fffebe",
    borderRadius: 3,
    position: "relative",
    lineHeight: "normal",
    letterSpacing: "normal",
  },
  mobileTimeSlotChangeMsg: {
    padding: "10px 5px",
    fontSize: 12,
  },
  desktopTimeSlotChangeMsg: {
    padding: "14px 5px",
    fontSize: 14,
  },
  courierDateRangeFont: {
    fontSize: 14,
    fontWeight: 500,
  },
  courierTimeFont: {
    fontSize: 12,
    fontWeight: 500,
  },
  courierTimeDelivery: {
    width: "15%",
    fontSize: 12,
    display: "inline-block",
    verticalAlign: "top",
    lineHeight: "normal",
  },
  courierDeliveryTime: {
    background: "#f8f8f8",
    padding: "10px 5px 10px 10px",
    borderRadius: 3,
    position: "relative",
    marginBottom: 12,
    margin: 0,
  },
}));

/**
 * Component to render delivery shipping info
 *
 * @param {*} param0 props passed to component
 * @param {string} param0.shippingDetails object contains shipping details.
 * @param {string} param0.selectedCurrency string contains selected currency.
 * @param {string} param0.currencies list of currencies.
 * @param {string} param0.postalCode postalCode.
 * @param {string} param0.setSelectedGiftIndex setSelectedGiftIndex.
 * @param {string} param0.giftIndex giftIndex.
 * @returns {React.ReactElement} jsx for the delivery shipping info component.
 */
const DeliveryShippingInfo = ({
  shippingDetails,
  selectedCurrency,
  currencies,
  timeSlotChangeMsg,
  isMobile,
  postalCode,
  setEnableCheckoutDomesticCalendar,
  setEnableCheckoutIntlCalendar,
  setSelectedGiftIndex,
  giftIndex,
  mainproduct,
  ...props
}) => {
  const classes = useStyles(props);
  const { deliveryDate, shippingMethodName, deliveryTimeSlot, shippingPrice } = shippingDetails;
  const isCourierDateRangeEnable = useSelector((state) => state.dateRangeSwitch?.isCourierDateRangeEnable);
  const courierDateRange =
    isCourierDateRangeEnable &&
    isCourierDateRangeEnable === "Y" &&
    getCourierDateRangeFromDates(shippingDetails?.leadTimeDeliveryDatesList);
  const isInternationalEnable = useSelector((state) => state.productDetail?.isInternationalEnable);

  /**
   * This method is used to check the product is international or not
   *
   * @returns {boolean}  will return true or false
   */
  const checkIsInternationalProduct = () => {
    return mainproduct.primaryProductCategoryId === productTypes.INTERNATIONAL;
  };

  /**
   * this component shows delivery date for non courier products in cart preview
   *
   * @returns {React.Fragment} this jsx shows delivery date for non courier products
   */
  const shippingWithoutDateRange = () => (
    <>
      <div className={classes.deliveryTime}>
        <div className={classes.dayDate}>
          <span className={classes.selectedDate}>{deliveryDate.date}</span>
          <div className={classes.month}>
            <span className={classes.selectedMonthDay}>{deliveryDate.month}</span>
            <span className={classes.selectedMonthDay}>{deliveryDate.day}</span>
          </div>
        </div>
        <div className={classes.timeDelivery}>
          <span>
            {`${shippingMethodName} : `}
            {shippingPrice ? (
              <CurrencyDetails
                priceStyle={{ color: "#222222" }}
                price={shippingPrice}
                selectedCurrency={selectedCurrency}
                currencies={currencies}
              />
            ) : (
              <span className={classes.shippingPriceFree}>Free</span>
            )}
          </span>
          <span className={classes.selectedTime}>{deliveryTimeSlot}</span>
        </div>
      </div>
    </>
  );

  /**
   * this method enables the calender and shipping selection based on domestic
   *
   */
  const changeDelivery = () => {
    setSelectedGiftIndex(giftIndex);
    if (checkIsInternationalProduct()) {
      if (isInternationalEnable === "Y") {
        setEnableCheckoutDomesticCalendar(true);
      } else {
        setEnableCheckoutIntlCalendar(true);
      }
    } else {
      setEnableCheckoutDomesticCalendar(true);
    }
  };

  return (
    <div>
      <div className={classes.deliveryDate}>
        <span>Delivery Date</span>
        <span
          role="button"
          className={classes.deliveryDateChange}
          onClick={changeDelivery}
          onKeyPress={changeDelivery}
          tabIndex={0}
        >
          Change
        </span>
      </div>
      {courierDateRange?.startDate ? (
        <>
          <div className={classes.courierDeliveryTime}>
            <div className={classes.courierDateRangeFont}>
              <span>{courierDateRange.startDate}</span>
              <span> - </span>
              <span>{courierDateRange.endDate}</span>
              <DateRangeInfo />
            </div>
            <div className={classes.courierTimeFont}>
              <span>{shippingMethodName}</span>
              <span> : </span>
              <span>{deliveryTimeSlot}</span>
              <span> - </span>
              <div className={classes.courierTimeDelivery}>
                <span>
                  {shippingPrice ? (
                    <CurrencyDetails
                      priceStyle={{ color: "#222222" }}
                      price={shippingPrice}
                      selectedCurrency={selectedCurrency}
                      currencies={currencies}
                    />
                  ) : (
                    <span className={classes.shippingPriceFree}>Free</span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        shippingWithoutDateRange()
      )}
      {timeSlotChangeMsg ? (
        <div
          className={`${classes.timeSlotChangeMsg} ${
            isMobile ? classes.mobileTimeSlotChangeMsg : classes.desktopTimeSlotChangeMsg
          }`}
        >
          <span>{timeSlotChangeMsg}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

const propTypes = {
  shippingDetails: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedCurrency: PropTypes.string.isRequired,
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  timeSlotChangeMsg: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  postalCode: PropTypes.string,
  setEnableCheckoutDomesticCalendar: PropTypes.func,
  setEnableCheckoutIntlCalendar: PropTypes.func,
  setSelectedGiftIndex: PropTypes.func,
  mainproduct: PropTypes.objectOf(PropTypes.any),
  giftIndex: PropTypes.string,
};

DeliveryShippingInfo.defaultProps = {
  postalCode: "",
  setEnableCheckoutDomesticCalendar: "",
  setEnableCheckoutIntlCalendar: "",
  setSelectedGiftIndex: () => {},
  giftIndex: "",
  mainproduct: {},
};

DeliveryShippingInfo.propTypes = propTypes;

export default DeliveryShippingInfo;
