import React, { useCallback, useEffect, useRef, useState } from "react";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { IconButton, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { setGaClickEventInteraction } from "../../../../src/ga/gaEvents";

const useStyles = makeStyles(() => ({
  iconBtnAnimate: {
    animation: "$iconWiggle 100ms linear infinite",
  },
  "@keyframes iconWiggle": {
    "0%": {
      transform: "rotate(0)",
    },
    "25%": {
      transform: "rotate(20deg)",
    },
    "50%": {
      transform: "rotate(0)",
    },
    "75%": {
      transform: "rotate(-20deg)",
    },
    "100%": {
      transform: "rotate(0)",
    },
  },
}));

/**
 * This component is used to show gift finder icon in the m-site header
 *
 * @param {object} root0 props from parent
 * @param {string} root0.currentCatalogId current catalog id
 *
 * @returns {React.ReactElement} - renders gift-finder-icon-btn component
 */
const GiftFinderIconBtn = ({ currentCatalogId }) => {
  const classes = useStyles();
  const [count, setCount] = useState(0);
  const rAFRef = useRef();
  const previousTimeRef = useRef();

  /**
   * counter function to update count at specific interval
   *
   * @param {number} timestamp data now
   */
  const counter = (timestamp) => {
    if (previousTimeRef.current !== undefined) {
      if (timestamp - previousTimeRef.current >= 2000) {
        previousTimeRef.current = timestamp;
        setCount((prevCount) => prevCount + 1);
      }
    } else {
      previousTimeRef.current = timestamp;
    }

    rAFRef.current = requestAnimationFrame(counter);
  };

  useEffect(() => {
    rAFRef.current = requestAnimationFrame(counter);
    return () => cancelAnimationFrame(rAFRef.current);
    // Should be called only on component first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const iconStyle = count % 2 === 0 ? classes.iconBtnAnimate : "";

  /**
   * Function navigates to cart preview screen when cart icon is clicked
   */
  const onIconClick = useCallback(() => {
    setGaClickEventInteraction(
      "home_page_gift_finder_top_nav_click",
      "home_page_interaction",
      "gift_finder_top_nav_click",
    );

    window.location.href = `${window.location.origin}/giftfinder?FNP_CURRENT_CATALOG_ID=${currentCatalogId}`;
  }, [currentCatalogId]);

  return (
    <IconButton color="inherit" disableRipple onClick={onIconClick} aria-label="Gift Finder Icon" className={iconStyle}>
      <CardGiftcardIcon />
    </IconButton>
  );
};

GiftFinderIconBtn.propTypes = {
  currentCatalogId: PropTypes.string,
};

GiftFinderIconBtn.defaultProps = {
  currentCatalogId: "india",
};

export default GiftFinderIconBtn;
