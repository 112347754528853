import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/core/styles";
import StickyCartPriceDetails from "./stickyCartPriceDetails";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";
import EmptyCart from "./emptyCart";
import CartListing from "./cartListing";
import { updateViewCartJSON, setFnpPageType, FnpPageType, iswalletCalculate } from "../../../src/utils/common";
import CartListingSkeleton from "./cartListingSkeleton";
import Loader from "../../mobile/plp/loader";
import useCartPreviewOmnitureSetup from "../../hooks/analytics/useCartPreviewOmnitureSetup";
import walletStyles from "../../common/styles/wallet-CheckBox.module.scss";
import WalletCheckBox from "../../common/walletCheckBox";
import useFNPWalletBalanceData from "../../hooks/analytics/useFNPWalletBalanceData";

const useStyles = makeStyles((theme) => ({
  loaderOverlay: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    zIndex: 1005,
    left: 0,
    top: 0,
    right: 0,
    overflowY: "hidden",
    backgroundColor: "rgba(10, 10, 10, 0.85)",
    backgroundPosition: "center",
  },
  cartListingContainer: { padding: "15px 0 1px" },
  desktopCartListingContainer: { padding: "16px", maxHeight: "330px", overflowY: "auto", overflowX: "hidden" },
  desktopLoaderOverlay: {
    left: 0,
    right: 0,
    bottom: 0,
    top: "53px",
    display: "flex",
    zIndex: 1005,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(10, 10, 10, 0.85)",
    backgroundPosition: "center",
    position: "absolute",
  },
  addMessage: {
    background: theme.color?.olive,
    padding: "5px",
    color: theme.color?.white,
    fontSize: "14px",
    textAlign: "left",
    borderRadius: "4px",
    marginBottom: "10px",
    lineHeight: "normal",
  },
  addMessageText: {
    verticalAlign: "super",
  },
  hide: {
    display: "none",
  },
}));

/**
 * This function in where all the cart component  has to be displayed on the cart page
 *
 * @param {object} param0 Component props
 * @param {Function} param0.handleProceedToAddon Function that will navigate to addon screen
 * @returns {React.ReactElement} jsx for the cart page.
 */
function CartPreview({ handleProceedToAddon, ...props }) {
  const classes = useStyles(props);
  const currencies = useSelector((store) => store.currenciesList.currencies || [], shallowEqual);
  const isMobile = useSelector((store) => store.appConfigs.isMobile);
  const { isOmnitureEnabled, isCdpEnabled } = useSelector((state) =>
    isMobile ? state.appConfigs.configData.mobile : state.appConfigs.configData.desktop,
  );
  const {
    cartSummary: cartData,
    isLoadingCartDetails: showCartSkeleton,
    isLoadingPncCard: showPncCartSkeleton,
    isLoadingAddonUpdate,
    isLoadingDeleteItem,
    firstTimeLoad,
    showAddItemToCart,
  } = useSelector((state) => state.cartDetails);
  const { checkLoginInfo: userDetails, cartInfo } = useSelector((state) => state.userDetails);
  const fvId = useSelector((state) => state.userDetails.cartId);
  const dispatch = useDispatch();
  const localCurrency = useSelector((store) => store.appData.selectedCurrency);
  const [checked, setChecked] = useState(true);
  const [checkedFromSession, setCheckedFromSession] = useState(true);
  const cashValueRX = useSelector((state) => state.walletDetails?.walletValue?.balance) || 0;
  const [currentBalanceWallet, setCurrentBalanceWallet] = useState(0);
  const { amountUsed, availableBalance } = iswalletCalculate(cartData?.total, currentBalanceWallet);
  const [availableAmount, setAvailableAmount] = useState({ amountUse: amountUsed, availableBal: availableBalance });
  const amountInStickyBar = availableAmount?.amountUse;
  const loggedInUser = useSelector((state) => state.userDetails?.checkLoginInfo?.loggedin);
  const localCurrencyINR = localCurrency === "INR";
  const fnpWalletSelected = useSelector((state) => state.walletDetails?.fnpWalletSelected);

  useFNPWalletBalanceData();

  useEffect(() => {
    setCurrentBalanceWallet(cashValueRX);
  }, [cashValueRX]);

  useEffect(() => {
    setAvailableAmount({ amountUse: amountUsed, availableBal: availableBalance });
  }, [amountUsed, availableBalance]);

  useEffect(() => {
    if (isMobile || !firstTimeLoad) {
      dispatch({ type: APP_CONSTANTS.GET_CART_SUMMARY_INFO_REQUEST });
      setFnpPageType(FnpPageType.CART_PREVIEW);
    }
    // disabled because we require component did mount not for did update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isCdpEnabled && window.cdp && fvId && cartData?.items?.length) {
      window.cdp.cartDetail(fvId);
    }
  }, [fvId, isCdpEnabled, isOmnitureEnabled, cartData]);

  useCartPreviewOmnitureSetup();

  /**
   * This function dispatches an action to update addon quantity
   *
   * @param {object} param0 item you want to update
   * @param {string} param0.productId Id of item.
   * @param {number} param0.itemIndex Index of item.
   * @param {number} param0.quantity New quantity of item
   */
  const handleQuantityUpdate = ({ quantity, itemIndex, productId }) => {
    const params = {
      isCart: true,
      itemIndex,
      productId_quantity: `${productId}_${quantity}`,
      qty: quantity,
    };
    dispatch({ type: APP_CONSTANTS.UPDATE_ADDON_QUANTITY_REQUEST, payload: params });
  };

  /**
   * handle checked and unchecked wallet balance change
   *
   * @param {any} newChecked parameter to be checked and unchecked
   */
  const handleChildStateChange = (newChecked) => {
    setChecked(newChecked);
    sessionStorage.setItem("walletCheckboxState", newChecked);
  };

  /**
   * Function to get the value from session storage and initialize the `checked` state
   *
   */
  const fetchCheckedFromSessionStorage = () => {
    const storedValue = sessionStorage.getItem("walletCheckboxState");
    setCheckedFromSession(
      localCurrencyINR && (storedValue === "true" || (storedValue === null && fnpWalletSelected === "Y")),
    );
  };

  useEffect(() => {
    fetchCheckedFromSessionStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCurrencyINR, fnpWalletSelected]);

  useEffect(() => {
    setChecked(checkedFromSession);
  }, [checkedFromSession, localCurrencyINR]);

  /**
   *  This function dispatches an action to delete cart item
   *
   * @param {object} param0 item you want to delete
   * @param {string} param0.productId Id of item you want to delete
   * @param {number} param0.index Index of item you want to delete
   */
  const handleDeleteItem = ({ productId, index }) => {
    const params = {
      [`DELETE_${index}`]: index,
      productId,
      format: "json",
    };
    dispatch({ type: APP_CONSTANTS.DELETE_CART_ITEM_REQUEST, payload: params });
  };

  const CurrencyCheckBoxStyle = {
    opacity: localCurrencyINR ? 1 : 0.5,
  };

  if (
    (showCartSkeleton && !isLoadingAddonUpdate && !isLoadingDeleteItem) ||
    (showPncCartSkeleton && cartInfo?.cartTotalQuantity > 0)
  )
    return <CartListingSkeleton isMobile={isMobile} />;
  return (
    <>
      <div className={!showCartSkeleton ? "cart-container" : ""}>
        {(isMobile && !cartData?.items?.length) || !cartInfo?.cartTotalQuantity ? (
          <EmptyCart loggedIn={userDetails?.loggedin} isMobile={isMobile} />
        ) : (
          <>
            {isLoadingAddonUpdate || isLoadingDeleteItem ? (
              <div className={isMobile ? classes.loaderOverlay : classes.desktopLoaderOverlay}>
                <Loader />
              </div>
            ) : null}

            <Grid className={isMobile ? classes.cartListingContainer : classes.desktopCartListingContainer}>
              <div className={!showAddItemToCart && classes.hide}>
                <div className={classes.addMessage}>
                  <CheckCircleIcon />
                  <span id="add-message" className={classes.addMessageText}>
                    Item Added to Cart
                  </span>
                </div>
              </div>

              <CartListing
                isMobile={isMobile}
                cartData={updateViewCartJSON(cartData)}
                localCurrency={localCurrency}
                isMultiImageUploadOnVal
                updateQuantityAction={handleQuantityUpdate}
                deleteOrderItemAction={handleDeleteItem}
                currencies={currencies}
                handleProceedToAddon={handleProceedToAddon}
              />
              {loggedInUser && (
                <div className={walletStyles.cartWalletStyle}>
                  <WalletCheckBox
                    setAvailableAmount={setAvailableAmount}
                    onStateChange={handleChildStateChange}
                    currentBalanceWallet={currentBalanceWallet}
                    availableAmount={availableAmount}
                    checked={checked}
                    disabled={!localCurrencyINR}
                    CurrencyCheckBoxStyle={CurrencyCheckBoxStyle}
                  />
                </div>
              )}
            </Grid>
            <StickyCartPriceDetails
              isMobile={isMobile}
              priceInfo={updateViewCartJSON(cartData).paymentInfo}
              loggedIn={userDetails?.loggedin}
              localCurrency={localCurrency}
              currencies={currencies}
              availableAmountUsed={amountInStickyBar}
              checkBoxChecked={!checked}
              localCurrencyINR={localCurrencyINR}
            />
          </>
        )}
      </div>
    </>
  );
}
const propTypes = {
  handleProceedToAddon: PropTypes.func.isRequired,
};

CartPreview.propTypes = propTypes;

export default CartPreview;
