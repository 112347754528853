import { takeEvery, put } from "redux-saga/effects";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { setWalletCashLabelValue, getWalletBalance } from "../../src/actions/login-actions";

/**
 * This method is used to Set wallet Text
 *
 */
function* setTextOfWallet() {
  try {
    const data = yield setWalletCashLabelValue();
    const newData = JSON.parse(data.data.value);
    if (data.status === 200) {
      yield put({ type: APP_CONSTANTS.SET_WALLET_CASH_LABEL_SUCCESS, payload: newData });
    }
  } catch (error) {
    yield put({ type: APP_CONSTANTS.SET_WALLET_CASH_LABEL_ERROR, error });
  }
}

/**
 * This method is used to Set wallet Value
 *
 */
function* setValueOfWallet() {
  try {
    const data = yield getWalletBalance();

    if (data?.data?.isSuccess) {
      yield put({
        type: APP_CONSTANTS.SET_WALLET_SUCCESS,
        payload: { balance: data?.data?.response?.balance },
      });
    } else {
      yield put({
        type: APP_CONSTANTS.SET_WALLET_SUCCESS,
        payload: { balance: 0 },
      });
    }
  } catch (error) {
    yield put({ type: APP_CONSTANTS.SET_WALLET_ERROR, error });
  }
}

/**
 * This method is used to yield success response of CHECK_LOGIN_CART_INFO action
 *
 */
function* walletSaga() {
  yield takeEvery(APP_CONSTANTS.SET_WALLET_CASH_LABEL, setTextOfWallet);
  yield takeEvery(APP_CONSTANTS.SET_WALLET, setValueOfWallet);
}

export default walletSaga;
