import { HYDRATE } from "next-redux-wrapper";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

const InitialState = {
  success: false,
  error: false,
  walletText: "",
  valueTimer: "",
  walletValue: {},
  fnpWalletSelected: "",
};

/**
 * This method is the reducer for actions that are specific to wallet transactions.
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function walletReducer(state = InitialState, action) {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.walletDetails,
      };
    case APP_CONSTANTS.SET_WALLET_CASH_LABEL:
      return {
        ...state,
        success: false,
        error: false,
      };
    case APP_CONSTANTS.SET_WALLET_CASH_LABEL_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        valueTimer: action.payload?.wallet?.timer,
        walletText: action.payload?.wallet?.label?.fnpcash,
        fnpWalletSelected: action.payload?.wallet?.fnpWalletSelected,
      };
    case APP_CONSTANTS.SET_WALLET_CASH_LABEL_ERROR:
      return {
        ...state,
        success: false,
        error: true,
      };
    case APP_CONSTANTS.SET_WALLET:
      return {
        ...state,
        success: false,
        error: false,
      };
    case APP_CONSTANTS.SET_WALLET_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        walletValue: action.payload,
      };
    case APP_CONSTANTS.SET_WALLET_ERROR:
      return {
        ...state,
        success: false,
        error: true,
      };
    default:
      return state;
  }
}
export default walletReducer;
